import styled from 'styled-components';
import sectionBackground from '../../assets/images/sitemap/background.png';

export const SitemapStyled = styled.div`
  position: relative;
  margin: 0;

  background-color: ${(props) => props.theme.palette.blueColor};
  background-image: url(${sectionBackground});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;

  padding-bottom: 8px;

  min-height: 700px;
`;

export const SitemapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
  }
`;

export const SitemapColumn = styled.div`
  ${(props) => props.theme.media.tablet} {
    flex: 0 0 33%;
    width: 33%;
    padding: 24px;
  }
`;

export const SitemapUl = styled.ul`
  color: #ffffff;

  // default
  ul {
    margin-top: 8px;
    list-style-type: circle;

    > li {
      margin-top: 8px;
    }

    > li > a {
      color: #ffffff;
      font-size: 14px;
      line-height: 1.1;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // LEVEL-0
  // -------
  padding-left: 0;
  list-style: none;

  > li {
  }

  > li > a {
    color: #ffffff;
    font-size: 24px;
    line-height: 1.1;
    font-weight: 700;
    text-decoration: none;

    position: relative;
    padding-left: 16px;
    margin-left: 16px;

    &::before {
      position: absolute;
      content: '';
      top: 14px;
      left: -16px;
      background: ${(props) => props.theme.palette.greenColor};
      height: 3px;
      width: 16px;
    }
  }

  // LEVEL-1
  // -------
  > li > ul {
    list-style: none;
    padding-left: 0;

    > li {
      border-top: 1px solid #ffffff;
      padding-top: 18px;
      margin-top: 24px;
    }
    > li > a {
      color: #ffffff;
      font-size: 18px;
      line-height: 1.1;
      font-weight: 700;
      text-decoration: none;
    }
  }

  // LEVEL-2
  // -------
  > li > ul > li > ul {
    margin-top: 16px;
    list-style-type: disc;

    > li > a {
      color: #ffffff;
      font-size: 16px;
      line-height: 1.1;
      text-decoration: none;
    }
  }
`;
