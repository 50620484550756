import React from 'react';
import { HeaderProps } from './Header.types';
import { HeaderContent, HeaderDescription, HeaderStyled, HeaderSubtitle, HeaderTitle } from './Header.styled';
import Container from '../Container/Container';

const Header = ({ title, subtitle, description, backgroundImage }: HeaderProps): JSX.Element => (
  <HeaderStyled backgroundImage={backgroundImage}>
    <Container>
      <HeaderContent>
        {subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderDescription>{description}</HeaderDescription>
      </HeaderContent>
    </Container>
  </HeaderStyled>
);

export default Header;
