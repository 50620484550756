import React from 'react';
import {
  FlipBlockArrow,
  FlipBlockBack,
  FlipBlockDescription,
  FlipBlockFront,
  FlipBlockIcon,
  FlipBlockTitle,
  FlipBlockWrapper,
} from './FlipBlock.styled';
import imageArrow from '../../assets/icons/features-mark.svg';
import { FlipBlockProps } from './FlipBlock.types';

const FlipBlock = ({ icon, title, description }: FlipBlockProps): JSX.Element => (
  <FlipBlockWrapper>
    <FlipBlockFront>
      <FlipBlockIcon src={icon} alt={title} height="80px" />
      <FlipBlockTitle>{title}</FlipBlockTitle>
      <FlipBlockArrow src={imageArrow} alt="arrow" />
    </FlipBlockFront>
    <FlipBlockBack>
      <FlipBlockDescription>{description}</FlipBlockDescription>
      <FlipBlockArrow src={imageArrow} alt="arrow" />
    </FlipBlockBack>
  </FlipBlockWrapper>
);

export default FlipBlock;
