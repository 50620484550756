import styled from 'styled-components';
import imageRoadBg from '../../assets/images/videosense/road.jpg';
import imageFaceBg from '../../assets/images/videosense/record.jpg';

export const GreenroadVideosenseStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const PhoneBlockWrapper = styled.div`
  position: relative;
`;

export const PhoneBlockContentWrapper = styled.div`
  display: flex;

  > div {
    flex: 0 1 auto;
    margin: 16px;
  }

  > div:last-child {
    div {
      padding: 16px;
      background-color: ${(props) => props.theme.palette.blueColor};

      ${(props) => props.theme.media.tablet} {
        padding: 8px 16px;
      }
    }
  }

  ${(props) => props.theme.media.phone} {
    > div:first-child {
      display: none;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;

    margin-bottom: 24px;
    padding: 16px;

    ${(props) => props.theme.media.tablet} {
      font-size: 26px;
    }
    ${(props) => props.theme.media.desktop} {
      font-size: 30px;
    }
  }

  p {
    color: #ffffff;
    font-size: 18px;
  }
`;

export const PhoneBlockBackground = styled.div`
  position: absolute;
  top: 120px;
  z-index: -1;

  background-color: ${(props) => props.theme.palette.blueColor};
  height: 250px;
  width: 100%;

  ${(props) => props.theme.media.phone} {
    display: none;
  }
`;

export const FaceBlockWrapper = styled.div`
  height: 800px;

  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${imageFaceBg});
  background-position: top center;

  p {
    color: #ffffff;
    font-weight: 700;
  }

  ${(props) => props.theme.media.phone} {
    height: auto;
    background-image: none;
    background-color: ${(props) => props.theme.palette.darkBlueColor};
  }
`;

export const FaceBlockContent = styled.div`
  display: flex;
  align-items: center;
  padding: 60px 50px;

  > div {
    flex: 0 1 auto;
    margin: 0 16px;
  }

  ${(props) => props.theme.media.phone} {
    flex-direction: column;
    padding: 32px 16px;
  }
`;

export const RoadBlock = styled.div`
  width: 100%;
  height: 290px;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${imageRoadBg});
  background-position: center;
`;

export const ButtonBlockWrapper = styled.div`
  position: relative;
  margin-top: 48px;
`;
export const ButtonBlockImage = styled.img`
  position: absolute;
  top: -80px;
  left: -100px;
  z-index: 1;

  width: 328px;
  height: 328px;

  ${(props) => props.theme.media.phone} {
    display: none;
  }
`;
export const ButtonBlockContent = styled.p`
  width: 80%;
  margin-left: 20%;

  ${(props) => props.theme.media.phone} {
    width: 100%;
    margin-left: 0;
  }
`;
