import styled from 'styled-components';

const quoteWidth = 64;
export const QuoteHorizontal = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.textColor};

  margin-left: ${quoteWidth + 16}px;

  &::before {
    position: absolute;
    content: '';
    top: 9px;
    left: -${quoteWidth + 16}px;
    background-color: ${(props) => props.theme.palette.greenColor};
    height: 4px;
    width: ${quoteWidth}px;
  }
`;
