import Joi from 'joi';
import countryList from '../../data/countryList';
import fleetSizeList from '../../data/fleetSizeList';
import industryList from '../../data/industryList';

export const contactSchema = Joi.object({
  firstName: Joi.string().required().label('First Name'),
  lastName: Joi.string().required().label('Last Name'),
  companyName: Joi.string().label('Company Name').allow(''),
  jobName: Joi.string().label('Job Title').allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email'),
  phone: Joi.string().required().label('Phone Number'),
  country: Joi.string()
    .valid(...countryList)
    .required()
    .messages({
      'any.only': `{{#label}} must be valid country`,
    })
    .label('Country'),
  fleetSize: Joi.string()
    .valid(...fleetSizeList)
    .required()
    .messages({
      'any.only': `{{#label}} is not allowed to be empty`,
    })
    .label('Fleet Size'),
  message: Joi.optional(),
  subscribe: Joi.bool().required(),
  captcha: Joi.number(), // TODO: Сделать required
});

export const requestSchema = Joi.object({
  firstName: Joi.string().required().label('First Name'),
  lastName: Joi.string().required().label('Last Name'),
  companyName: Joi.string().label('Company Name').allow(''),
  jobName: Joi.string().label('Job Title').allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email'),
  phone: Joi.string().required().label('Phone Number'),
  city: Joi.string().required().label('City'),
  country: Joi.string()
    .valid(...countryList)
    .required()
    .messages({
      'any.only': `{{#label}} must be valid country`,
    })
    .label('Country'),
  fleetSize: Joi.string()
    .valid(...fleetSizeList)
    .required()
    .messages({
      'any.only': `{{#label}} is not allowed to be empty`,
    })
    .label('Fleet Size'),
  industry: Joi.string()
    .valid(...industryList)
    .required()
    .messages({
      'any.only': `{{#label}} is not allowed to be empty`,
    })
    .label('Industry'),
  message: Joi.optional(),
  subscribe: Joi.bool().required(),
  captcha: Joi.number(), // TODO: Сделать required
});

export const callRequestSchema = Joi.object({
  name: Joi.string().required().label('Name'),
  phone: Joi.string().required().label('Phone Number'),
  time: Joi.optional().label('Call Time'),
});
