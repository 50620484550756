export enum DeviceImageBorderType {
  'Round',
  'Circle',
}
export type DeviceBlockType = {
  name: string;
  image: string;
  borderType: DeviceImageBorderType;
  number: number;
  description: string | JSX.Element | JSX.Element[];
};
