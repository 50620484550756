import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Container } from '../Container/Container.styled';
import footerBgLeft from '../../assets/images/background/footer-bg-left.png';
import footerBgRight from '../../assets/images/background/footer-bg-right.png';

export const FooterWrapper = styled.div`
  background: ${(props) => props.theme.palette.darkBlueColor};

  ${Container} {
    background-image: url(${footerBgLeft}), url(${footerBgRight});
    background-repeat: no-repeat, no-repeat;
    background-position: left bottom, right top;
    background-size: auto 43px, auto 64px;

    padding: 32px 8px;
  }
`;

export const FooterLink = styled(NavLink)`
  position: relative;
  font-size: 13px;
  padding-left: 8px;
  transition: color 0.15s ease;
  margin-left: 16px;

  &::before {
    position: absolute;
    content: '';
    bottom: 7px;
    left: -16px;
    background: ${(props) => props.theme.palette.greenColor};
    height: 1px;
    width: 16px;
  }

  color: #ffffff;
  text-decoration: none !important;

  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.greenColor};
  }

  &.active {
    font-weight: 700;
  }
`;

export const Copyright = styled.div`
  padding: 16px;
  border-top: 1px solid #ffffff;
  background: ${(props) => props.theme.palette.darkBlueColor};

  p {
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
`;

export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  flex: 1 0 auto;

  > div:first-child {
    height: 50px;
    line-height: 58px;
  }

  div {
    padding: 4px 0;
  }
`;
