import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from '../../lib/scrollLock';
import { ModalLead, ModalList, ModalMainImage, ModalMainWrapper } from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/ai-drving-coach.png';

const MixVisionModal04 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="AI Driving Coach"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>The AI Driving Coach guides drivers in real-time with unobtrusive visual alerts.</ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="AI Driving Coach" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Dashboard mounted</li>
            <li>Real-time driver coaching for AI events</li>
            <li>Visual alerts on the unit</li>
            <li>Can be used to replace the road facing camera beep / voice alerts with visual alerts</li>
          </ModalList>
        </div>
      </ModalMainWrapper>
    </Modal>
  );
});

export default MixVisionModal04;
