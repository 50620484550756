import styled from 'styled-components';

export const NumberBlockWrapper = styled.div`
  max-width: 230px;
`;

export const NumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.img`
  height: 68px;
  width: auto;
`;

export const Number = styled.div`
  font-weight: 700;
  font-size: 68px;
  line-height: 1;
`;

export const Divider = styled.div`
  width: 100%;

  span {
    display: inline-block;
    height: 3px;
    background: #a79f92;
  }
  span:first-child {
    background: ${(props) => props.theme.palette.greenColor};
    margin-right: 1%;
  }
`;

export const Description = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  padding: 8px 16px;
`;

export const Link = styled.div`
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  line-height: 1;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.textColor};
  }
`;
