import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import '../../assets/styles/slider.scss';
import slider01 from '../../assets/images/home/carousel-1.jpg';
import slider02 from '../../assets/images/home/carousel-2.jpg';
import slider03 from '../../assets/images/home/carousel-3.jpg';
import {
  AboutDescription,
  AboutImageWrapper,
  AboutTextWrapper,
  AboutTitle,
  AboutWrapper,
  AutoplaySliderWrapper,
  BottomSectionTitle,
  GetStartedBlock,
  GetStartedBlockButtonWrapper,
  GetStartedBlockFlex,
  FlagBlockFlex,
  HomeStyled,
  OutlinedButton,
  OutlinedWhiteButton,
  WhiteButton,
} from './Home.styled';
import Container from '../../components/Container/Container';
import SliderTextBlock from '../../components/SliderTextBlock/SliderTextBlock';
import Section from '../../components/Section/Section';
import getStartedLogo from '../../assets/images/home/get-started-logo.png';
import aboutSectionBg from '../../assets/images/home/about-bg.jpg';
import aboutSectionLogo from '../../assets/images/home/about-rgr.png';
import getStartedBottomBg from '../../assets/images/home/get-started-down-background.jpg';
import flag from '../../assets/images/home/flag.png';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import CustomersBlock from '../../components/CustomersBlock/CustomersBlock';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormContact from '../../components/Form/FormContact';
import VendorsBlock from '../../components/VendorsBlock/VendorsBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

// eslint-disable-next-line
const AutoplaySlider = withAutoplay(AwesomeSlider);

function Home(): JSX.Element {
  return (
    <HomeStyled>
      <SEO
        title="RGRauto"
      />

      <Section style={{ padding: '0' }} backgroundColor="rgb(91 173 244)">
        <Container>
          <FlagBlockFlex>
            <img src={flag} alt="WE STAND WITH ISRAEL" height={60} />
            <div>WE STAND WITH ISRAEL</div>
            <img src={flag} alt="WE STAND WITH ISRAEL" height={60} />
          </FlagBlockFlex>
        </Container>
      </Section>

      <AutoplaySliderWrapper>
        <AutoplaySlider play cancelOnInteraction={false} interval={10000} fillParent bullets={false}>
          <div data-src={slider01}>
            <SliderTextBlock
              title="Advanced Driver Assistance Systems"
              description="Over 65 Million vehicles worldwide are equipped with Mobileye Technology. While others record the collision, Mobileye prevents it."
              links={[
                {
                  title: 'Read more',
                  url: '/mobileye',
                  type: 'nav',
                  variant: 'default',
                },
                {
                  title: 'Request a demo',
                  url: '/demo',
                  type: 'nav',
                  variant: 'white',
                },
              ]}
            />
          </div>
          <div data-src={slider02}>
            <SliderTextBlock
              title="Driver Monitoring Systems"
              description="Computer Vision in-cabin sensing solutions for fleets. AI-based Driver Monitoring Systems help drivers make safer and better in-car experiences, improve mobility experiences and increase fleet safety."
              links={[
                {
                  title: 'Read more',
                  url: '/driver-monitoring-system',
                  type: 'nav',
                  variant: 'default',
                },
                {
                  title: 'Request a demo',
                  url: '/demo',
                  type: 'nav',
                  variant: 'white',
                },
              ]}
            />
          </div>
          <div data-src={slider03}>
            <SliderTextBlock
              title="Fleet Telematics and Safety Management Systems"
              description="Advanced Fleet Safety and Management Solutions that offer fleet operators unlimited access to information about their vehicles and drivers, with a host of features, tools and reports to help improve the driver safety, fleet efficiency and maximize return on investment."
              links={[
                {
                  title: 'Read more',
                  url: '/fleet-telematics',
                  type: 'nav',
                  variant: 'default',
                },
                {
                  title: 'Request a demo',
                  url: '/demo',
                  type: 'nav',
                  variant: 'white',
                },
              ]}
            />
          </div>
        </AutoplaySlider>
      </AutoplaySliderWrapper>
      <Section>
        <Container>
          <GetStartedBlock>
            <GetStartedBlockFlex>
              <img src={getStartedLogo} alt="RGR" height={60} />
              <div>We turn great products into smart solutions</div>
            </GetStartedBlockFlex>
            <GetStartedBlockButtonWrapper>
              <OutlinedButton exact to="/contact">
                Get started
              </OutlinedButton>
            </GetStartedBlockButtonWrapper>
          </GetStartedBlock>
        </Container>
      </Section>

      <Section style={{ padding: '0' }} backgroundColor="#001C54" backgroundImage={aboutSectionBg}>
        <Container>
          <AboutWrapper>
            <AboutTextWrapper>
              <AboutTitle>About RGRauto</AboutTitle>
              <AboutDescription>
                RGRauto is a leading global provider of integrated safety and optimization solutions for various-sized
                fleets in multiple industries
              </AboutDescription>
              <OutlinedWhiteButton exact to="/about">
                Read more
              </OutlinedWhiteButton>
            </AboutTextWrapper>
          </AboutWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Our customers</SectionTitle>
          <p>We are trusted by world’s leading brands</p>
          <CustomersBlock />
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Contact us</SectionTitle>
          <p style={{ fontSize: '18px' }}>Have a question? Would you like to speak with us?</p>
          <Card>
            <CardDescription>
              Please fill out the form below and we will <br />
              contact you as soon as possible
            </CardDescription>
            <FormContact />
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>We are partnered with World’s Leading Vendors</SectionTitle>
          <p style={{ fontSize: '18px' }}>
            We integrate products by a select group of industry leaders to provide best-in-class solutions and services
            for our clients
          </p>
          <VendorsBlock />
        </Container>
      </Section>

      <Section
        backgroundColor="#001C54"
        backgroundImage={getStartedBottomBg}
        style={{ minHeight: '300px', textAlign: 'center' }}
      >
        <Container>
          <BottomSectionTitle>Make your fleet safer and smarter with RGRAuto</BottomSectionTitle>
          <WhiteButton exact to="/contact">
            Get started
          </WhiteButton>
        </Container>
      </Section>
    </HomeStyled>
  );
}

export default Home;
