import React from 'react';
import { DeviceBlockDescription, DeviceBlockImage, DeviceBlockNumber, DeviceBlockWrapper } from './DeviceBlock.styled';
import { DeviceBlockType } from './DeviceBlock.types';

function DeviceBlock({ name, image, borderType, number, description }: DeviceBlockType): JSX.Element {
  return (
    <DeviceBlockWrapper>
      <DeviceBlockImage borderType={borderType}>
        <img src={image} alt={name} />
      </DeviceBlockImage>
      <div>
        <DeviceBlockNumber>{number}</DeviceBlockNumber>
        <DeviceBlockDescription>{description}</DeviceBlockDescription>
      </div>
    </DeviceBlockWrapper>
  );
}

export default DeviceBlock;
