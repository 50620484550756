import React, { useState } from "react";
import { CookieBannerStyled, CookieBannerWrapper } from "./CookieBanner.styled";
import Container from "../Container/Container";
import { OutlinedButton } from "../../pages/Home/Home.styled";

const CookieBanner = (): JSX.Element => {
  const [isCookieAccept, setIsCookieAccept] = useState(false);

  const handleOkButton = () => {
    setIsCookieAccept(true);
    localStorage.setItem('isCookieAccept', 'true');
  }
  const showBanner =
    localStorage.getItem('isCookieAccept') !== 'true' &&
    isCookieAccept === false;

  return showBanner && (
    <CookieBannerStyled>
      <Container>
        <CookieBannerWrapper>
          <div>
            <p>This Website is using cookies. We use cookies to ensure that we give you the best experience on our site.</p>
            <p>If you continue using the site, we'll assume that you are happy to receive all cookies on this website.</p>
          </div>
          <div>
            <OutlinedButton as="button" onClick={handleOkButton}>
              Ok, thanks
            </OutlinedButton>
          </div>
        </CookieBannerWrapper>
      </Container>
    </CookieBannerStyled>
  );
};

export default CookieBanner;