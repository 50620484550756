import React from 'react';
import { CSSObject } from 'styled-components';
import { CardWrapper } from './Card.styled';

const Card = ({ children, style }: { children: JSX.Element | JSX.Element[]; style?: CSSObject }): JSX.Element => (
  <CardWrapper style={style}>{children}</CardWrapper>
);

Card.defaultProps = {
  style: {},
};

export default Card;
