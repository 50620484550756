import styled from 'styled-components';
import backgroundBlueBox from '../../assets/images/background/isticker-background.png';
import { SectionStyled } from '../../components/Section/Section.styled';
import { Container } from '../../components/Container/Container.styled';

export const MixTrackingStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const BlueBoxSection = styled(SectionStyled)`
  background-image: url(${backgroundBlueBox});
  background-color: ${(props) => props.theme.palette.blueColor};
  padding: 0;

  ${Container} {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 400px;
  }
`;
export const BlueBoxContent = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;

  color: #ffffff;

  padding: 36px;
  margin: 72px 0;
  border: 1px solid #ffffff;

  p {
    width: 70%;

    ${(props) => props.theme.media.phone} {
      width: 100%;
    }
  }
`;
export const BlueBoxImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  margin: 0;
  padding: 0;

  img {
    display: block;
  }

  ${(props) => props.theme.media.phone} {
    display: none;
  }
`;

export const FeaturesWrapper = styled.div`
  margin-top: 36px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
    //justify-content: space-between;
  }

  > div {
    flex: 0 0 33%;
    width: 33%;
    text-align: center;

    img {
      width: 100%;
      max-height: 393px;
    }
  }

  > div:first-child,
  > div:last-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.greenColor};
  }

  ${(props) => props.theme.media.phone} {
    h2 {
      display: none;
    }
    > div {
      flex: 0 0 100%;
      width: 100%;
      padding: 24px;
      border-bottom: 1px solid ${(props) => props.theme.palette.greenColor};

      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
`;
