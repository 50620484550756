import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home/Home';
import FleetTelematics from './pages/FleetTelematics/FleetTelematics';
import Demo from './pages/Demo/Demo';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Industries from './pages/Industries/Industries';
import DriverMonitoringSystem from './pages/DriverMonitoringSystem/DriverMonitoringSystem';
import Cipia from './pages/Cipia/Cipia';
import Greenroad from './pages/Greenroad/Greenroad';
import MixTelematicts from './pages/MixTelematicts/MixTelematicts';
import CloudWise from './pages/CloudWise/CloudWise';
import Geotab from './pages/Geotab/Geotab';
import Ituran from './pages/Ituran/Ituran';
// import MobileyeShield from './pages/MobileyeShield/MobileyeShield';
import Mobileye8Connect from './pages/Mobileye8Connect/Mobileye8Connect';
import Mobileye from './pages/Mobileye/Mobileye';
import GeotabGo9 from './pages/GeotabGo9/GeotabGo9';
import GreenroadDrive from './pages/GreenroadDrive/GreenroadDrive';
import CipiaFleetSense from './pages/CipiaFleetSense/CipiaFleetSense';
import Solutions from './pages/Solutions/Solutions';
import Products from './pages/Products/Products';
import CloudWiseISticker from './pages/CloudWiseISticker/CloudWiseISticker';
import MixFleetManager from './pages/MixFleetManager/MixFleetManager';
import MixVision from './pages/MixVision/MixVision';
import MixTracking from './pages/MixTracking/MixTracking';
import Sitemap from './pages/Sitemap/Sitemap';
import GreenroadVideosense from './pages/GreenroadVideosense/GreenroadVideosense';

const routes = [
  {
    path: '/solutions',
    component: Solutions,
  },
  {
    path: '/products',
    component: Products,
  },
  {
    path: '/demo',
    component: Demo,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/industries',
    component: Industries,
  },
  {
    path: '/sitemap',
    component: Sitemap,
  },
  {
    path: '/driver-monitoring-system',
    component: DriverMonitoringSystem,
  },
  {
    path: '/fleet-telematics',
    component: FleetTelematics,
  },
  {
    path: '/cipia/fleet-sense-fs10',
    component: CipiaFleetSense,
  },
  {
    path: '/cipia',
    component: Cipia,
  },
  {
    path: '/greenroad/videosense',
    component: GreenroadVideosense,
  },
  {
    path: '/greenroad/drive',
    component: GreenroadDrive,
  },
  {
    path: '/greenroad',
    component: Greenroad,
  },
  {
    path: '/mix-telematics/mix-tracking',
    component: MixTracking,
  },
  {
    path: '/mix-telematics/mix-fleet-manager',
    component: MixFleetManager,
  },
  {
    path: '/mix-telematics/mix-vision-ii',
    component: MixVision,
  },
  {
    path: '/mix-telematics',
    component: MixTelematicts,
  },
  {
    path: '/cloud-wise/i-sticker',
    component: CloudWiseISticker,
  },
  {
    path: '/cloud-wise',
    component: CloudWise,
  },
  {
    path: '/geotab/go9',
    component: GeotabGo9,
  },
  {
    path: '/geotab',
    component: Geotab,
  },
  {
    path: '/ituran',
    component: Ituran,
  },
  // {
  //   path: '/mobileye/mobileye-shield',
  //   component: MobileyeShield,
  // },
  {
    path: '/mobileye/mobileye-8-connect',
    component: Mobileye8Connect,
  },
  {
    path: '/mobileye',
    component: Mobileye,
  },
  {
    path: '/',
    component: Home,
  },
];

function RouteWithSubRoutes(props: { path: string; component: () => JSX.Element; key: number }) {
  return (
    <Route
      path={props.path}
      exact
      strict
      render={() => (
        // pass the sub-routes down to keep nesting
        <props.component />
      )}
    />
  );
}

const Router = (): JSX.Element => (
  <Switch>
    {routes.map((route, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <RouteWithSubRoutes key={i} {...route} />
    ))}
    <Redirect from="/home" to="/" />
  </Switch>
);

export default Router;
