import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
  ProductCardText,
  ProductCardImage,
  ProductCardLink,
  ProductCardLinks,
  ProductCardTitle,
  ProductCardWrapper,
  ProductCardImageWrapper,
  ProductCardDescriptionWrapper,
} from './ProductCard.styled';
import { ProductCardLinkProps, ProductCardProps } from './ProductCard.types';
import VideoModal from '../../modals/VideoModal/VideoModal';

const getLink = (link: ProductCardLinkProps, key: number): JSX.Element => {
  const openModal = (id: string, title: string, videoCode: string) => {
    NiceModal.show(id, { title, videoCode })
      .then(() => {
        // do something if the task in the modal finished.
      })
      .catch(() => {});
  };
  switch (link.type) {
    case 'nav':
      return (
        <ProductCardLink
          key={key}
          href={link.url}
          target={link.target ?? '_self'}
          title={link.description ?? link.title}
        >
          {link.title}
        </ProductCardLink>
      );
    case 'video':
      NiceModal.register(link.url, VideoModal);
      return (
        <ProductCardLink
          key={key}
          onClick={() => openModal(link.url, link.description ?? link.title, link.url)}
          title={link.description ?? link.title}
        >
          {link.title}
        </ProductCardLink>
      );
    default:
      return <></>;
  }
};

const ProductCard = ({ title, description, image, imageAtLeft, links }: ProductCardProps): JSX.Element => (
  <ProductCardWrapper imageAtLeft={imageAtLeft}>
    <ProductCardImageWrapper>
      <ProductCardImage image={image} />
    </ProductCardImageWrapper>
    <ProductCardDescriptionWrapper>
      <ProductCardTitle>{title}</ProductCardTitle>
      <ProductCardText>{description}</ProductCardText>
      <ProductCardLinks>{links.map((link, i) => getLink(link, i))}</ProductCardLinks>
    </ProductCardDescriptionWrapper>
  </ProductCardWrapper>
);
ProductCard.defaultProps = {
  description: null,
  imageAtLeft: true,
  links: null,
};

export default ProductCard;
