import styled from 'styled-components';
import headerBg from '../../assets/images/mobileye/header.jpg';
import frameBg from '../../assets/images/mobileye/main.jpg';
import vehiclesBg from '../../assets/images/mobileye/vehicles.jpg';

export const MobileyeStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
  padding-bottom: 8px;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  z-index: 0;

  height: 495px;
  width: 100%;

  background-color: ${(props) => props.theme.palette.headerBackground};
  background-image: url(${headerBg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
`;

// Like Video
const framePadding = 36;
export const FramedContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 540px;
  padding: 0 ${framePadding}px;
`;
export const FramedContentFrame = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - ${framePadding * 2}px);
  margin: ${framePadding}px -${framePadding}px;

  border: 2px solid ${(props) => props.theme.palette.blueColor};

  ${(props) => props.theme.media.phone} {
    display: none;
  }
`;
export const FramedContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-image: url(${frameBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  color: #ffffff;
  font-weight: 700;
  font-size: 18px;

  padding: 64px;

  > div {
    position: relative;
    margin-left: 24px;
  }

  > div::before {
    position: absolute;
    content: '';
    top: 0;
    left: -24px;
    background-color: ${(props) => props.theme.palette.greenColor};
    height: 100%;
    width: 4px;
  }

  ${(props) => props.theme.media.phone} {
    overflow: hidden;
  }
`;

export const VehiclesSection = styled.section`
  background-color: ${(props) => props.theme.palette.headerBackground};
  background-image: url(${vehiclesBg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 100%;

  min-height: 250px;
  padding: 48px 24px;
`;

export const VehiclesTitle = styled.div`
  color: #ffffff;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > div:first-child {
    font-size: 96px;
    line-height: 1;
    font-weight: 700;
  }

  > div:last-child {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
    margin-top: 24px;

    ${(props) => props.theme.media.tablet} {
      margin-left: 48px;
      margin-top: 0;
    }
  }
`;

export const VehiclesText = styled.div`
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
  color: #ffffff;
  text-align: center;

  margin-top: 36px;
`;
