import React from 'react';
import Container from '../../components/Container/Container';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { FeatureCardsWrapper, NumbersBlockWrapper } from '../Mobileye8Connect/Mobileye8Connect.styled';
import FeatureCard from '../../components/FeatureCard/FeatureCard';
import fms01 from '../../assets/images/mobileye/fms-1-b.png';
import fms01Hover from '../../assets/images/mobileye/fms-1-w.png';
import fms02 from '../../assets/images/mobileye/fms-2-b.png';
import fms02Hover from '../../assets/images/mobileye/fms-2-w.png';
import fms03 from '../../assets/images/mobileye/fms-3-b.png';
import fms03Hover from '../../assets/images/mobileye/fms-3-w.png';
import fms04 from '../../assets/images/mobileye/fms-4-b.png';
import fms04Hover from '../../assets/images/mobileye/fms-4-w.png';
import fms05 from '../../assets/images/mobileye/fms-5-b.png';
import fms05Hover from '../../assets/images/mobileye/fms-5-w.png';
import fms06 from '../../assets/images/mobileye/fms-6-b.png';
import fms06Hover from '../../assets/images/mobileye/fms-6-w.png';
import Section from '../../components/Section/Section';
import numberSectionBg from '../../assets/images/mobileye8/number-section.png';
import NumberBlock from '../../components/NumberBlock/NumberBlock';
import percent01 from '../../assets/images/mobileye/percent1.png';
import percent02 from '../../assets/images/mobileye/percent2.png';
import percent03 from '../../assets/images/mobileye/percent3.png';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import {
  FramedContent,
  FramedContentFrame,
  FramedContentWrapper,
  HeaderBackground,
  MobileyeStyled,
  VehiclesSection,
  VehiclesText,
  VehiclesTitle,
} from './Mobileye.styled';
import mobileyeDevice from '../../assets/images/mobileye/mobileye-device.png';
import { QuoteHorizontal } from '../../components/Quote/Quote.styled';
import ProductCard from '../../components/ProductCard/ProductCard';
import mobileye8Image from '../../assets/images/mobileye/mobileye8.jpg';
import mobileyeShieldImage from '../../assets/images/mobileye/mobileye-shield.jpg';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Mobileye(): JSX.Element {
  return (
    <MobileyeStyled>
      <SEO
        title="RGRauto | Mobileye"
      />
      <HeaderBackground />
      <Section backgroundColor="transparent" style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle variant="inverted">Mobileye</PageTitle>
          <p style={{ color: '#FFFFFF', marginBottom: '36px' }}>
            AI-based Collision Avoidance Technology by Mobileye provides
            <br /> road-tested, advanced driver safety.
          </p>
          <Card style={{ overflow: 'hidden' }}>
            <img src={mobileyeDevice} height={360} style={{ float: 'right' }} alt="Mobileye" />
            <QuoteHorizontal>
              <p>
                Just like when we drive, using our vision to scan the road and judge how far we are from obstacles and
                what potential dangers they pose — a video sensor can also scan the way ahead with the advantage of
                never becoming fatigued or distracted.
              </p>
            </QuoteHorizontal>
            <p>
              The key element in the Mobileye Collision Avoidance System is in the ability to mimic how people
              understand the roadscape. Through the use of artificial intelligence, Mobileye’s EyeQ® chip is able to
              identify potential hazards and alert drivers in real-time, providing him enough time to perform a maneuver
              and avoid a collision.
            </p>
            <p>
              Fleet organizations worldwide have experienced significant reductions in collisions and associated costs
              with Mobileye. We can help your fleet achieve the same. Act to Prevent Collisions by providing your
              drivers real-time alerts a few second before a potential collision occurs.
            </p>

            <div style={{ margin: '0 -80px' }}>
              <FramedContentWrapper style={{ margin: '48px 0 24px' }}>
                <FramedContentFrame />
                <FramedContent>
                  <div>
                    The Mobileye Collision Avoidance System is available with a single, forward-facing vision sensor
                    suitable for almost any vehicle, or in a multi-sensor solution designed specifically for large
                    commercial vehicles with hazardous blind spots
                  </div>
                </FramedContent>
              </FramedContentWrapper>
            </div>
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <ProductCard
            title="Mobileye 8 Connect"
            description="for fleets"
            image={mobileye8Image}
            imageAtLeft
            links={[
              {
                title: 'Watch Video',
                description: 'Mobileye 8 Connect',
                url: 'JPGUA9uKjJg',
                type: 'video',
              },
              {
                title: 'Read More',
                description: 'Read More about Mobileye 8 Connect',
                url: '/mobileye/mobileye-8-connect',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
          {/*<ProductCard*/}
          {/*  title="Mobileye Shield Plus"*/}
          {/*  description="for large vehicles"*/}
          {/*  image={mobileyeShieldImage}*/}
          {/*  imageAtLeft={false}*/}
          {/*  links={[*/}
          {/*    {*/}
          {/*      title: 'Watch Video',*/}
          {/*      description: 'Mobileye Shield Plus',*/}
          {/*      url: 'uIvtK5Sb1jk',*/}
          {/*      type: 'video',*/}
          {/*    },*/}
          {/*    {*/}
          {/*      title: 'Read More',*/}
          {/*      description: 'Read More about Mobileye Shield Plus',*/}
          {/*      url: '/mobileye/mobileye-shield',*/}
          {/*      type: 'nav',*/}
          {/*    },*/}
          {/*    {*/}
          {/*      title: 'Contact Us',*/}
          {/*      url: '/contact',*/}
          {/*      type: 'nav',*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>FMS and reporting features</SectionTitle>
          <FeatureCardsWrapper>
            <FeatureCard
              icons={{
                main: fms01,
                hover: fms01Hover,
              }}
              title="Prevent the next collision"
              description={
                "Mobileye helps fleets to prevent collisions, not prove blame. Prevent injuries to your workforce with Mobileye's collision avoidance system."
              }
            />
            <FeatureCard
              icons={{
                main: fms02,
                hover: fms02Hover,
              }}
              title="Improves driving behavior"
              description="Driving with the system installed, drivers naturally begin to adopt safer driving habits."
            />
            <FeatureCard
              icons={{
                main: fms03,
                hover: fms03Hover,
              }}
              title="Affordable & cost-effective"
              description="Reducing collisions with Mobileye can save money on repairs and subsequent insurance premiums."
            />
            <FeatureCard
              icons={{
                main: fms04,
                hover: fms04Hover,
              }}
              title="Retrofit to any vehicle"
              description="Mobileye can be installed in any type of vehicle, regardless the make, model and year. Equip your fleet with AI-powered collision avoidance, without the need for new vehicles with ADAS"
            />
            <FeatureCard
              icons={{
                main: fms05,
                hover: fms05Hover,
              }}
              title="Get real-time reports and alerts"
              description={
                "While Mobileye assists your drivers on board, you get real-time alerts and reports on dangerous driver behavior, maneuvers and violations. Get timely reports, actionable insights, statistics, drivers ratings and recommendations to improve drivers' habits."
              }
            />
            <FeatureCard
              icons={{
                main: fms06,
                hover: fms06Hover,
              }}
              title={'Seamless integration with telematics\n'}
              description={
                "If your fleet has a telematics system, we will help you to integrate it with Mobileye, so in addition to the standard telematics alerts, you will get important data from Mobileye's video sensor. This comprehensive data is essential for modern fleets."
              }
            />
          </FeatureCardsWrapper>
        </Container>
      </Section>

      <VehiclesSection>
        <Container>
          <VehiclesTitle>
            <div>65M+</div>
            <div>
              Vehicles are equipped with <br />
              Mobileye technology
            </div>
          </VehiclesTitle>
          <VehiclesText>
            Reducing collisions with Mobileye alerts saves money on repairs and subsequent insurance premiums.
            Typically, fleet managers see a return on investment within 12 months.
          </VehiclesText>
        </Container>
      </VehiclesSection>

      <Section backgroundImage={numberSectionBg}>
        <Container>
          <NumbersBlockWrapper>
            <NumberBlock icon={percent01} number={73} percents description="Cut of in-scope collision costs" />
            <NumberBlock icon={percent02} number={91} percents description="Reduction in collision- related costs" />
            <NumberBlock
              icon={percent03}
              number={97}
              percents
              description="Drop in collision-related insurance claims"
            />
          </NumbersBlockWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MobileyeStyled>
  );
}

export default Mobileye;
