import styled from 'styled-components';

export const Title = styled.div`
  position: relative;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 700;
  color: #ffffff;

  padding: 32px 0 0;
  margin: 0 0 32px;

  &::after {
    position: absolute;
    content: '';
    bottom: -24px;
    left: 0;
    background-color: #ffffff;
    height: 2px;
    width: 124px;
  }
`;
export const Description = styled.div`
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffff;

  padding: 16px 0;
`;
export const Link = styled.a<{variant: string}>`
  display: inline-block;
  min-width: 200px;

  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  
  border-radius: 5px;
  padding: 16px 24px;

  text-align: center;
  text-decoration: none;

  transition: color, background-color 0.15s ease;

  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => {
    switch (props.variant) {
      case 'white':
        return '#ffffff';
      case 'default':
      default:
        return '#ffffff';
    }
  }};
  color: ${(props) => {
    switch (props.variant) {
      case 'white':
        return props.theme.palette.blueColor;
      case 'default':
      default:
        return '#ffffff';
    }
  }};
  background-color: ${(props) => {
    switch (props.variant) {
      case 'white':
        return '#ffffff';
      case 'default':
      default:
        return 'transparent';
    }
  }};

  &:hover {
    color: ${(props) => {
      switch (props.variant) {
        case 'white':
          return '#ffffff';
        case 'default':
        default:
          return props.theme.palette.blueColor;
      }
    }};
    background-color: ${(props) => {
      switch (props.variant) {
        case 'white':
          return props.theme.palette.darkBlueColor;
        case 'default':
        default:
          return '#ffffff';
      }
    }};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 8px;

  ${Link} + ${Link} {
    margin-top: 16px;
  }

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
    ${Link} + ${Link} {
      margin-left: 16px;
      margin-top: 0;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  max-width: 582px;
  padding: 16px;
`;
