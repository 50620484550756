import styled from 'styled-components';
import leftBg from '../../assets/images/background/product-card-bg-left.png';
import rightBg from '../../assets/images/background/product-card-bg-right.png';

export const ProductCardImageWrapper = styled.div`
  height: 320px;
  min-height: 250px;
`;

export const ProductCardDescriptionWrapper = styled.div`
  padding: 36px;

  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50%;

  ${(props) => props.theme.media.tablet} {
    padding: 48px;
  }
`;

export const ProductCardWrapper = styled.div<{ imageAtLeft: boolean }>`
  background: #ffffff;
  box-shadow: 0 4px 45px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${ProductCardImageWrapper},
  ${ProductCardDescriptionWrapper} {
    width: 100%;
    flex: 0 1 100%;
  }

  ${ProductCardImageWrapper} {
    order: 0;
  }

  ${ProductCardDescriptionWrapper} {
    order: 1;
    background-image: url(${(props) => (props.imageAtLeft ? rightBg : leftBg)});
    background-position: ${(props) => (props.imageAtLeft ? 'right bottom' : 'left bottom')};
  }

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;

    ${ProductCardImageWrapper},
    ${ProductCardDescriptionWrapper} {
      width: 50%;
      flex: 0 1 50%;
    }

    ${ProductCardImageWrapper} {
      order: ${(props) => (props.imageAtLeft ? 0 : 1)};
    }

    ${ProductCardDescriptionWrapper} {
      order: ${(props) => (props.imageAtLeft ? 1 : 0)};
    }
  }

  & + & {
    margin-top: 48px;
  }
`;

export const ProductCardImage = styled.div<{ image: string }>`
  height: 100%;
  width: 100%;

  background-color: transparent;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ProductCardTitle = styled.div`
  position: relative;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 700;
  color: ${(props) => props.theme.palette.blueColor};
  margin: 0 0 48px;

  &::after {
    position: absolute;
    content: '';
    bottom: -24px;
    left: 0;
    background-color: ${(props) => props.theme.palette.blueColor};
    height: 2px;
    width: 114px;
  }
`;

export const ProductCardText = styled.div`
  font-size: 24px;
  line-height: 1.2;
  color: ${(props) => props.theme.palette.blueColor};
`;

export const ProductCardLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.greenColor};
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
`;

export const ProductCardLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 48px;

  a {
    margin: 8px;
    cursor: pointer;
  }
`;
