import styled from 'styled-components';
import { BorderBlockImageProps, BorderBlockWrapperProps } from './BorderBlock.types';

const verticalMargin = 48;
const horizontalMargin = 36;

export const BorderBlockImage = styled.div<BorderBlockImageProps>`
  position: relative;
  min-width: ${(props) => props.width + (props.offset?.left || 0) + (props.offset?.right || 0)}px;
  width: ${(props) => props.width + (props.offset?.left || 0) + (props.offset?.right || 0)}px;

  div {
    position: absolute;
    top: -${verticalMargin * 2}px;
    left: ${(props) => props.offset?.left || 0}px;
    right: ${(props) => props.offset?.right || 0}px;
    margin: auto;

    min-height: calc(100% + ${verticalMargin * 4}px);
    width: 100%;
    height: 100%;

    background-color: ${(props) => props.background || 'transparent'};
    box-shadow: ${(props) => (props.background ? '5px 5px 50px rgba(0, 0, 0, 0.05)' : 'none')};
    border-radius: 5px;

    background-image: url(${(props) => props.image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  // For mobile
  ${(props) => props.theme.media.phone} {
    align-self: center;
    width: 100%;
    min-width: 100%;
    min-height: 300px;

    div {
      top: 0;
      left: 0;
      right: 0;
      min-height: 100%;
      max-width: 100%;
    }
  }
`;

export const BorderBlockText = styled.div`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  color: #000000;

  padding: 0 8px;

  flex: 0 1 auto;
`;

export const BorderBlockWrapper = styled.div<BorderBlockWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  margin: ${verticalMargin * 1.5}px 0;

  border: 2px solid ${(props) => props.theme.palette.greenColor};
  padding: ${verticalMargin / 1.5}px ${horizontalMargin}px;

  &::after {
    clear: both;
  }

  // For mobile
  ${BorderBlockImage} {
    order: 0;
  }

  ${BorderBlockText} {
    order: 1;
  }

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;

    ${BorderBlockImage} {
      order: ${(props) => (props.imagePosition === 'left' ? 0 : 1)};
    }

    ${BorderBlockText} {
      order: ${(props) => (props.imagePosition === 'left' ? 1 : 0)};
    }

    ${BorderBlockText} {
      margin-left: ${(props) => props.imageOffset?.left || 0}px;
      margin-right: ${(props) => props.imageOffset?.right || 0}px;
    }
  }
`;
