import React from 'react';
import { NavLink } from 'react-router-dom';
import Card from '../Card/Card';
import { FlexWrapper, ImageBlock, MainBlock, ProductButtonsWrapper, ProductLinkWrapper } from './CompanyCard.styled';
import LogoImage from '../LogoImage/LogoImage';

type ProductButton = {
  title: string;
  url: string;
};
type ProductCardProps = {
  name: string;
  logo: string;
  image: string;
  buttons: ProductButton[];
  children: JSX.Element[];
};

function CompanyCard({ name, logo, image, buttons, children }: ProductCardProps): JSX.Element {
  return (
    <Card>
      <FlexWrapper>
        <MainBlock>
          <LogoImage src={logo} name={name} />
          <hr />
          {children}
        </MainBlock>
        <ImageBlock image={image} />
      </FlexWrapper>
      <div style={{ textAlign: 'center' }}>
        <ProductButtonsWrapper>
          {buttons.map((button, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ProductLinkWrapper key={i}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              <NavLink exact to={button.url} key={i}>
                {button.title}
              </NavLink>
            </ProductLinkWrapper>
          ))}
        </ProductButtonsWrapper>
      </div>
    </Card>
  );
}

export default CompanyCard;
