import React from 'react';
import { IndustriesCardsWrapper, IndustriesStyled } from './Industries.styled';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import { PageTitle } from '../../components/PageTitle/PageTitle.styled';
import { PageLeadStyled } from '../../components/PageTitle/PageLead.styled';
import IndustryCard from '../../components/IndustryCard/IndustryCard';
import constructionImage from '../../assets/images/industries/construction@2x.jpg';
import courierImage from '../../assets/images/industries/courier-and-delivery@2x.jpg';
import dealershipImage from '../../assets/images/industries/dealership@2x.jpg';
import fieldServicesImage from '../../assets/images/industries/field-services@2x.jpg';
import foodImage from '../../assets/images/industries/food-and-beverage@2x.jpg';
import heavyTruckImage from '../../assets/images/industries/heavy-truck@2x.jpg';
import motorpoolImage from '../../assets/images/industries/motorpool-@2x.jpg';
import oilGasImage from '../../assets/images/industries/oil-gas-and-mining@2x.jpg';
import pharmaceuticalImage from '../../assets/images/industries/pharmaceutical@2x.jpg';
import policeImage from '../../assets/images/industries/police-and-first-responders@2x.jpg';
import sharedMobilityImage from '../../assets/images/industries/shared-mobility@2x.jpg';
import towingImage from '../../assets/images/industries/towing@2x.jpg';
import utilitiesImage from '../../assets/images/industries/utilities@2x.jpg';
import wasteImage from '../../assets/images/industries/waste-and-recycling@2x.jpg';
import winterImage from '../../assets/images/industries/winter-operations@2x.jpg';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Industries(): JSX.Element {
  return (
    <IndustriesStyled>
      <SEO
        title="RGRauto | Industries"
      />
      <Section style={{ padding: '0' }}>
        <Container>
          <PageTitle>Industries</PageTitle>
          <PageLeadStyled>
            RGRauto is a leading global provider of integrated safety and optimization solutions for various-sized
            fleets in multiple industries.
          </PageLeadStyled>
        </Container>
      </Section>

      <Section style={{ paddingTop: '16px' }}>
        <Container>
          <IndustriesCardsWrapper>
            <IndustryCard title="Construction" image={constructionImage} />
            <IndustryCard title="Courier and delivery" image={courierImage} />
            <IndustryCard title="Dealerships" image={dealershipImage} />
            <IndustryCard title="Field services" image={fieldServicesImage} />
            <IndustryCard title="Food and beverage" image={foodImage} />
            <IndustryCard title="Heavy truck" image={heavyTruckImage} />
            <IndustryCard title="Motorpool" image={motorpoolImage} />
            <IndustryCard title="Oil, gas and mining" image={oilGasImage} />
            <IndustryCard title="Pharmaceutical" image={pharmaceuticalImage} />
            <IndustryCard title="Police & first responders" image={policeImage} />
            <IndustryCard title="Shared mobility" image={sharedMobilityImage} />
            <IndustryCard title="Towing" image={towingImage} />
            <IndustryCard title="Utilities" image={utilitiesImage} />
            <IndustryCard title="Waste and recycling" image={wasteImage} />
            <IndustryCard title="Winter operations" image={winterImage} />
          </IndustriesCardsWrapper>
        </Container>
      </Section>
    </IndustriesStyled>
  );
}

export default Industries;
