import React from 'react';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { GreenroadDriveStyled } from './GreenroadDrive.styled';
import headerBackground from '../../assets/images/drive/header.jpg';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import Section from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import monoblockImage from '../../assets/images/drive/monoblock.png';
import notebookImage from '../../assets/images/drive/notebook.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function GreenroadDrive(): JSX.Element {
  return (
    <GreenroadDriveStyled>
      <SEO
        title="RGRauto | GreenRoad Drive"
      />
      <Header
        title={
          <>
            GreenRoad Drive<sup>™</sup>
            <br />
            Software-Only App
          </>
        }
        description="Turn driver mobile devices into intelligent, in-vehicle driving and training coaches – for employees, contractors and remote drivers."
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock image={monoblockImage} imagePosition="right" imageWidth={350}>
            <p>
              <strong>GreenRoad Drive™</strong> downloads and is ready to use in minutes, supporting enterprise
              digitalization processes while promoting safety across the business without interfering with other mobile
              apps.
            </p>
          </BorderBlock>
        </Container>
      </Section>
      <Section>
        <Container>
          <BorderBlock image={notebookImage} imagePosition="left" imageWidth={700} imageOffset={{ left: -60 }}>
            <p>
              Take an efficient, proactive approach to fleet safety and operations management using GreenRoad’s KPI
              dashboards and artificial intelligence (AI) based deviation-from-norm data, with full drill-down into the
              details. Access via your internet browser or the GreenRoad Drive™ app.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </GreenroadDriveStyled>
  );
}

export default GreenroadDrive;
