import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from '../../lib/scrollLock';
import { ModalLead, ModalList, ModalMainImage, ModalMainWrapper } from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/road-facing-camera.png';

const MixVisionModal03 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="In-Cab Facing Camera"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>
        The road-facing camera detects AI events whilst on the road including collision warning, lane The in-cab camera
        records real-time visual and audio footage of the cab interior (no AI events).
      </ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="In-Cab Facing Camera" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Windshield mounted</li>
            <li>Full colour image with wide-angle view of the cab</li>
            <li>
              Built-in infrared LED’s with 3m illumination to record video, inside the cab during low light conditions
              or at night
            </li>
            <li>Built-in microphone for audio recording 920P @ 10 frames a second</li>
          </ModalList>
        </div>
      </ModalMainWrapper>
    </Modal>
  );
});

export default MixVisionModal03;
