import styled from 'styled-components';

export const MenuBlockWrapper = styled.div`
  position: fixed;
  z-index: 50;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const MenuWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 24%);
  position: relative;
  z-index: 50;
`;

export const MainMenuWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
`;
export const MainMenuLogo = styled.div`
  margin-right: auto;

  a {
    position: relative;
    display: inline-block;
  }
`;
export const MainMenuButton = styled.button`
  background-color: ${(props) => props.theme.palette.darkBlueColor};
  color: #ffffff;
  border: 2px solid ${(props) => props.theme.palette.darkBlueColor};

  padding: 12px 16px;
  border-radius: 5px;

  min-width: 165px;

  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

  &:visited {
    background-color: ${(props) => props.theme.palette.darkBlueColor};
    color: #ffffff;
  }

  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.darkBlueColor};
    background-color: #ffffff;
  }

  ${(props) => props.theme.media.phone} {
    display: none;
  }
`;
export const MobileMenuToggleButton = styled(MainMenuButton)`
  display: block;
  min-width: auto;
  padding: 8px;
  line-height: 1;
  cursor: pointer;

  svg path {
    stroke: #ffffff;
  }

  &:hover {
    svg path {
      stroke: ${(props) => props.theme.palette.darkBlueColor};
    }
  }

  ${(props) => props.theme.media.tablet} {
    display: none;
  }
`;
export const MainMenuList = styled.nav`
  display: flex;
  list-style: none;

  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;

  align-items: center;

  a + a {
    border-left: 2px solid #d7d7d7;
  }

  a {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;

    color: #757575;
    text-decoration: none;
    transition: all 0.15s ease;

    &:visited {
      color: #808080;
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.palette.darkBlueColor};
    }

    &.active {
      color: ${(props) => props.theme.palette.darkBlueColor};
    }
  }

  ${(props) => props.theme.media.phone} {
    display: none;
  }
`;

export const ExtraMenuDivider = styled.div`
  height: 1px;
  background-color: #a9a9a9;

  margin: 16px 0;
  padding: 0;
`;
export const ExtraMenuLinkList = styled.ul`
  margin: 16px 0;
  color: #808080;

  padding: 0;
  list-style: inside;
  line-height: 1.8;

  li {
    position: relative;

    &:hover {
      background: #e0e0e0;
    }

    a {
      color: #808080;
      text-decoration: none !important;
      transition: all 0.15s ease;

      // temporary hack
      display: inline-block;
      width: 90%;

      &:visited {
        color: #808080;
      }

      &:hover,
      &:active {
        color: ${(props) => props.theme.palette.darkBlueColor};
        font-weight: 700;
      }

      &.active {
        color: ${(props) => props.theme.palette.greenColor};
        font-weight: 700;
      }
    }
  }

  li::marker {
    content: '• ';
  }
`;
export const ExtraMenuSubTitle = styled.div`
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;

  color: ${(props) => props.theme.palette.darkBlueColor};

  a {
    color: ${(props) => props.theme.palette.darkBlueColor};
    text-decoration: none !important;
    transition: all 0.15s ease;

    &:visited {
      color: ${(props) => props.theme.palette.darkBlueColor};
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.palette.greenColor};
    }

    &.active {
      color: ${(props) => props.theme.palette.greenColor};
      font-weight: 700;
    }
  }
`;
export const ExtraMenuTitle = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;

  color: ${(props) => props.theme.palette.darkBlueColor};
  text-transform: uppercase;

  a {
    color: ${(props) => props.theme.palette.darkBlueColor};
    text-decoration: none !important;
    transition: all 0.15s ease;

    &:visited {
      color: ${(props) => props.theme.palette.darkBlueColor};
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.palette.greenColor};
    }

    &.active {
      color: ${(props) => props.theme.palette.greenColor};
    }
  }
`;
export const ExtraMenuColumn = styled.div`
  width: 300px;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #00a651;
  border-top: none;

  padding: 24px 0;

  & + & {
    margin-left: -1px; // Hack for border collapse
  }

  ${ExtraMenuTitle},
  ${ExtraMenuSubTitle},
  ${ExtraMenuLinkList} > li {
    padding: 0 32px;
  }

  ${ExtraMenuDivider} {
    margin: 16px 32px;
  }
`;
export const ExtraMenu = styled.div`
  margin: 0 auto;
  padding: 0;

  position: relative;
  background-color: transparent;
  z-index: 49;

  display: flex;
  justify-content: center;
  align-items: start;
`;
