import styled from 'styled-components';

export const EdgeBlockStyled = styled.div`
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;

  > div {
    flex: 0 1 auto;
    margin: 16px;
  }

  min-height: 160px;
  padding: 16px;
`;
export const EdgeIcon = styled.div``;
export const EdgeContentWrapper = styled.div``;
export const EdgeTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.darkBlueColor};
`;
export const EdgeDescription = styled.div``;
