import React from 'react';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import {
  DevicesSection,
  DeviceBlocksWrapper,
  Mobileye8ConnectStyled,
  NumbersBlockWrapper,
  FeatureCardsWrapper,
  BackgroundImage,
  ImagesSection,
} from './Mobileye8Connect.styled';
import Container from '../../components/Container/Container';
import Header from '../../components/Header/Header';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import Card from '../../components/Card/Card';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import Section from '../../components/Section/Section';
import AlertsSwitcher from '../../components/AlertsSwitcher/AlertsSwitcher';
import DeviceBlock from '../../components/DeviceBlock/DeviceBlock';
import NumberBlock from '../../components/NumberBlock/NumberBlock';
import { DeviceImageBorderType } from '../../components/DeviceBlock/DeviceBlock.types';
import FeatureCard from '../../components/FeatureCard/FeatureCard';
import headerBackground from '../../assets/images/mobileye8/header.jpg';
import device01 from '../../assets/images/mobileye8/retrofit-01.png';
import device02 from '../../assets/images/mobileye8/retrofit-02.png';
import device03 from '../../assets/images/mobileye8/retrofit-03.png';
import videoPreview from '../../assets/images/mobileye8/video.png';
import numberSectionBg from '../../assets/images/mobileye8/number-section.png';
import percent01 from '../../assets/images/mobileye/percent1.png';
import percent02 from '../../assets/images/mobileye/percent2.png';
import percent03 from '../../assets/images/mobileye/percent3.png';
import fms01 from '../../assets/images/mobileye/fms-1-b.png';
import fms01Hover from '../../assets/images/mobileye/fms-1-w.png';
import fms02 from '../../assets/images/mobileye/fms-2-b.png';
import fms02Hover from '../../assets/images/mobileye/fms-2-w.png';
import fms03 from '../../assets/images/mobileye/fms-3-b.png';
import fms03Hover from '../../assets/images/mobileye/fms-3-w.png';
import fms04 from '../../assets/images/mobileye/fms-4-b.png';
import fms04Hover from '../../assets/images/mobileye/fms-4-w.png';
import fms05 from '../../assets/images/mobileye/fms-5-b.png';
import fms05Hover from '../../assets/images/mobileye/fms-5-w.png';
import fms06 from '../../assets/images/mobileye/fms-6-b.png';
import fms06Hover from '../../assets/images/mobileye/fms-6-w.png';
import imageSectionLeft from '../../assets/images/mobileye8/left.png';
import imageSectionRight from '../../assets/images/mobileye8/right.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Mobileye8Connect(): JSX.Element {
  return (
    <Mobileye8ConnectStyled>
      <SEO
        title="RGRauto | Mobileye 8 Connect"
      />
      <Header
        subtitle="Improve Fleet Safety Today"
        title={
          <>
            Mobileye 8 Connect<sup>™</sup>
          </>
        }
        description="A forward collision avoidance system with enhanced advanced driver assistance system (ADAS) features, connectivity, and actionable data insights."
        backgroundImage={headerBackground}
      />

      <Section backgroundColor="#F5F5F5">
        <Container>
          <SectionTitle>Life Saving Alerts</SectionTitle>
          <AlertsSwitcher />
        </Container>
      </Section>

      <DevicesSection>
        <Container>
          <DeviceBlocksWrapper>
            <DeviceBlock
              name="The forward-facing camera"
              image={device01}
              borderType={DeviceImageBorderType.Round}
              number={1}
              description={
                <p>
                  The forward-facing camera mounted on the inside of the windshield behind the rear view mirror
                  constantly scans the road ahead and provides audio alerts when needed. The camera unit contains the
                  camera, EyeQ® chip, and speaker..
                </p>
              }
            />
            <DeviceBlock
              name="The EyeWatch™ display"
              image={device02}
              borderType={DeviceImageBorderType.Circle}
              number={2}
              description={
                <p>The EyeWatch™ display for visual alerts, mounted in the bottom corner of the windshield.</p>
              }
            />
            <DeviceBlock
              name="The GPS unit"
              image={device03}
              borderType={DeviceImageBorderType.Circle}
              number={3}
              description={<p>The GPS unit, mounted in the bottom corner of the windshield.</p>}
            />
          </DeviceBlocksWrapper>
        </Container>
      </DevicesSection>

      <Section backgroundColor="#F5F5F5">
        <Container>
          <VideoBlock code="JPGUA9uKjJg" preview={videoPreview} />
        </Container>
      </Section>

      <Section backgroundImage={numberSectionBg}>
        <Container>
          <NumbersBlockWrapper>
            <NumberBlock icon={percent01} number={73} percents description="Cut of in-scope collision costs" />
            <NumberBlock icon={percent02} number={91} percents description="Reduction in collision- related costs" />
            <NumberBlock
              icon={percent03}
              number={97}
              percents
              description="Drop in collision-related insurance claims"
            />
          </NumbersBlockWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>FMS and reporting features</SectionTitle>
          <FeatureCardsWrapper>
            <FeatureCard
              icons={{
                main: fms01,
                hover: fms01Hover,
              }}
              title="Prevent the next collision"
              description={
                "Mobileye helps fleets to prevent collisions, not prove blame. Prevent injuries to your workforce with Mobileye's collision avoidance system."
              }
            />
            <FeatureCard
              icons={{
                main: fms02,
                hover: fms02Hover,
              }}
              title="Improves driving behavior"
              description="Driving with the system installed, drivers naturally begin to adopt safer driving habits."
            />
            <FeatureCard
              icons={{
                main: fms03,
                hover: fms03Hover,
              }}
              title="Affordable & cost-effective"
              description="Reducing collisions with Mobileye can save money on repairs and subsequent insurance premiums."
            />
            <FeatureCard
              icons={{
                main: fms04,
                hover: fms04Hover,
              }}
              title="Retrofit to any vehicle"
              description="Mobileye can be installed in any type of vehicle, regardless the make, model and year. Equip your fleet with AI-powered collision avoidance, without the need for new vehicles with ADAS"
            />
            <FeatureCard
              icons={{
                main: fms05,
                hover: fms05Hover,
              }}
              title="Get real-time reports and alerts"
              description={
                "While Mobileye assists your drivers on board, you get real-time alerts and reports on dangerous driver behavior, maneuvers and violations. Get timely reports, actionable insights, statistics, drivers ratings and recommendations to improve drivers' habits."
              }
            />
            <FeatureCard
              icons={{
                main: fms06,
                hover: fms06Hover,
              }}
              title={'Seamless integration with telematics\n'}
              description={
                "If your fleet has a telematics system, we will help you to integrate it with Mobileye, so in addition to the standard telematics alerts, you will get important data from Mobileye's video sensor. This comprehensive data is essential for modern fleets."
              }
            />
          </FeatureCardsWrapper>
        </Container>
      </Section>

      <ImagesSection>
        <BackgroundImage image={imageSectionLeft} />
        <BackgroundImage image={imageSectionRight} />
      </ImagesSection>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </Mobileye8ConnectStyled>
  );
}

export default Mobileye8Connect;
