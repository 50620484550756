import styled from 'styled-components';
import checkListIcon from '../../assets/icons/form-checkbox.svg';

export const MixVisionStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const DevicesWrapper = styled.div``;
export const DeviceBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px;
  margin: 24px 0;

  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.15s ease;

  &:hover {
    background-color: #ffffff;
    border-color: ${(props) => props.theme.palette.greenColor};
  }
`;
export const DeviceBlockTitle = styled.h3`
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: ${(props) => props.theme.palette.darkBlueColor};
  margin: 0;
  text-transform: uppercase;
`;
export const DeviceBlockLink = styled.div`
  button {
    outline: none;
    border: none;
    background: none;
    color: ${(props) => props.theme.palette.greenColor};
    text-decoration: none;

    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 1px;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ModalLead = styled.p`
  font-size: 24px;
  color: ${(props) => props.theme.palette.blueColor};
`;

export const ModalMainWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;

  > div {
    flex: 0 1 100%;
    width: 100%;
  }

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;

    > div {
      flex: 0 1 50%;
      width: 50%;
    }
  }
`;

export const ModalMainImage = styled.div`
  background-color: #ffffff;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  padding: 72px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 300px;
  max-width: 300px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ModalList = styled.ul`
  li {
    list-style-image: url(${checkListIcon});
  }
`;

export const ModalFeaturesList = styled.div`
  margin-top: 24px;
`;

export const ModalFeatureBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;

  > div:first-child {
    margin-right: 24px;
  }

  p {
    margin-top: 8px;
  }

  & + & {
    border-top: 1px solid #d9d9d9;
    padding-top: 16px;
  }
`;
export const ModalFeatureIcon = styled.div``;
