import styled from 'styled-components';
import modalBg from '../../assets/images/modals/modal-bg.png';
import closeIconWhite from '../../assets/icons/close-icon-white.png';
import closeIconBlue from '../../assets/icons/close-icon-blue.png';

export const ModalRoot = styled.div`
  position: fixed;
  z-index: 1300;
  inset: 0;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;

  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const ModalContainer = styled.div`
  height: 100%;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const ModalWrapper = styled.div<{ variant: string }>`
  color: ${(props) => {
    switch (props.variant) {
      case 'white':
        return props.theme.palette.textColor;
      case 'blue':
      default:
        return '#ffffff';
    }
  }};
  background-color: ${(props) => {
    switch (props.variant) {
      case 'white':
        return '#ffffff';
      case 'blue':
      default:
        return props.theme.palette.darkBlueColor;
    }
  }};
  background-image: ${(props) => (props.variant === 'blue' ? `url(${modalBg})` : 'none')};
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;

  padding: 24px;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 800px;
  width: calc(100% - 64px);
`;

export const ModalTitle = styled.h3`
  font-weight: 700;
  font-size: 26px;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 24px;
  flex: 0 0 auto;

  position: relative;
  &::after {
    position: absolute;
    content: '';
    bottom: -12px;
    left: 0;
    background-color: ${(props) => props.theme.palette.greenColor};
    height: 4px;
    width: 64px;
  }
`;

export const ModalContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 16px 0;

  ${(props) => props.theme.media.tablet} {
    padding: 20px 24px;
  }
`;

export const ModalCloseButton = styled.button<{ variant: string }>`
  position: absolute;
  top: 24px;
  right: 24px;
  height: 24px;
  width: 24px;

  cursor: pointer;

  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${(props) => (props.variant === 'blue' ? `url(${closeIconWhite})` : `url(${closeIconBlue})`)};
  background-position: center;

  background-color: transparent;
  outline: none;
  border: none;
`;
