import styled from 'styled-components';
import { Container } from '../../components/Container/Container.styled';
import {
  DeviceBlockDescription,
  DeviceBlockImage,
  DeviceBlockWrapper,
} from '../../components/DeviceBlock/DeviceBlock.styled';
import retrofitBackground from '../../assets/images/mobileye8/retrofit-background.png';
import { NumberBlockWrapper } from '../../components/NumberBlock/NumberBlock.styled';

export const Mobileye8ConnectStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;

  padding-bottom: 8px;
`;

export const DevicesSection = styled.section`
  background-color: #ffffff;

  min-height: 700px;
  background-image: url(${retrofitBackground});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 600px;

  padding: 72px 0;

  ${Container} {
    display: block;
    background-color: transparent;
  }
`;

export const DeviceBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${DeviceBlockWrapper} {
    padding: 0 16px;
    max-width: 600px;
    text-align: justify;
  }

  ${DeviceBlockImage} {
    width: 140px;
    flex: 0 0 140px;
  }

  ${DeviceBlockDescription} {
    background: #ffffff;
  }

  ${DeviceBlockWrapper} + ${DeviceBlockWrapper} {
    margin-top: 24px;
  }

  ${(props) => props.theme.media.tablet} {
    align-items: flex-end;

    ${DeviceBlockWrapper} + ${DeviceBlockWrapper} {
      margin-top: 56px;
    }

    ${DeviceBlockImage} {
      width: auto;
      flex: 0 0 auto;

      img {
        padding: 24px;
        max-width: 148px;
      }
    }
  }
`;

export const NumbersBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${NumberBlockWrapper} + ${NumberBlockWrapper} {
    margin-top: 56px;
  }

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
    align-items: start;

    ${NumberBlockWrapper} + ${NumberBlockWrapper} {
      margin-top: 0;
    }
  }
`;

export const FeatureCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  & > div {
    margin: 16px;
  }

  ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
  }
`;

export const ImagesSection = styled.section`
  display: none;
  height: 400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  > div {
    display: inline-block;
  }

  > div:first-child {
    width: 70%;
  }

  > div:last-child {
    width: 30%;
    background-size: contain;
  }

  ${(props) => props.theme.media.tablet} {
    display: block;
  }
`;

export const BackgroundImage = styled.div<{ image: string }>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 100%;
  width: 100%;
`;
