import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from '../../lib/scrollLock';
import {
  ModalFeatureBlock,
  ModalFeaturesList,
  ModalLead,
  ModalList,
  ModalMainImage,
  ModalMainWrapper,
} from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/driver-facing-camera.png';
import icon01 from '../../assets/images/mix-vision/icon_2-1.png';
import icon02 from '../../assets/images/mix-vision/icon_2-2.png';
import icon03 from '../../assets/images/mix-vision/icon_2-3.png';
import icon04 from '../../assets/images/mix-vision/icon_2-4.png';
import icon05 from '../../assets/images/mix-vision/icon_2-5.png';
import icon06 from '../../assets/images/mix-vision/icon_2-6.png';

const MixVisionModal02 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Driver-Facing Camera"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>
        The driver-facing camera detects driver events including seatbelt, fatigue, phone use, distraction and smoking.
      </ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="Driver-Facing Camera" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Dash- or side-mounted</li>
            <li>Detects AI driver events</li>
            <li>Black and white image with infrared LED illumination</li>
            <li>Detects driver’s pupils even through sunglasses, at night or during low-light conditions</li>
            <li>Safe for driver’s eyes 920P @20 frames a second</li>
            <li>Built-in microphone for audio recording</li>
          </ModalList>
        </div>
      </ModalMainWrapper>

      <ModalFeaturesList>
        <ModalFeatureBlock>
          <div>
            <img src={icon01} height="50px" alt="Fatigue Warning" />
          </div>
          <div>
            <p>
              <strong>Fatigue Warning</strong>
            </p>
            <p>
              Triggered when yawning or eyes closed for 2 consecutive seconds and during frequent/excessive blinking
            </p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon02} height="50px" alt="Seat Belt Usage" />
          </div>
          <div>
            <p>
              <strong>Seat Belt Usage</strong>
            </p>
            <p>Detection of no seat belt usage for 4 consecutive seconds</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon03} height="50px" alt="Phone Use" />
          </div>
          <div>
            <p>
              <strong>Phone Use</strong>
            </p>
            <p>Phone-to-ear for 5 consecutive seconds</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon04} height="50px" alt="Driver Distraction" />
          </div>
          <div>
            <p>
              <strong>Driver Distraction</strong>
            </p>
            <p>Looking up or down for 4 consecutive seconds</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon05} height="50px" alt="Smoking" />
          </div>
          <div>
            <p>
              <strong>Smoking</strong>
            </p>
            <p>Smoking or gesture of smoking</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon06} height="50px" alt="Missing Driver" />
          </div>
          <div>
            <p>
              <strong>Missing Driver</strong>
            </p>
            <p>Driver monitoring camera covered or no driver present</p>
          </div>
        </ModalFeatureBlock>
      </ModalFeaturesList>
    </Modal>
  );
});

export default MixVisionModal02;
