import React from 'react';
import { AboutStyled } from './About.styled';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import aboutImage from '../../assets/images/about-us/about-us.jpg';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormContact from '../../components/Form/FormContact';
import { BigTextStyled } from '../../components/PageTitle/BigText.styled';
import { PageLeadStyled } from '../../components/PageTitle/PageLead.styled';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function About(): JSX.Element {
  return (
    <AboutStyled>
      <SEO
        title="RGRauto | About"
      />
      <Section style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle>About us</PageTitle>
          <PageLeadStyled>
            RGRauto is a leading global provider of integrated safety and optimization solutions for various-sized
            fleets in multiple industries.
          </PageLeadStyled>
          <p>
            RGRauto is partnered with market leaders such as Cipia, Cloud-Wise, Geotab, GreenRoad, Ituran, Mobileye, MiX
            Telematics, Orpak-Gilbarco, and is integrating them into unique turn-key and cost-effective solutions.
          </p>
          <BorderBlock image={aboutImage} imagePosition="left" imageWidth={527} imageBackground={null}>
            <p style={{fontSize: '16px', padding: '0 8px'}}>
              This wide range of innovative solutions, such as Connected ADAS, AI DMS, IoT Telematics and FMS, allows
              RGRauto to offer an extensive portfolio of different and competitive solutions to meet the unique
              requirements and budgets of different fleets.
              RGRauto provides trials, demos, pilots, and proof of concept (POC) to help prospects in evaluating its
              solutions and in order to tailor the perfect fit for a full, commercial deployment.
            </p>
          </BorderBlock>
          <p>
            We proudly serve private small-to-medium fleets as well as large corporate fleets such as ALD, Arval,
            AstraZeneca, Boehringer-Ingelheim, Coca-Cola, Danone, LeasePlan, Linde Gas, Maxxium, Nabors, Orange Business
            Services, Orica, Philip Morris, Praxair, Schlumberger, Shell, cities and local authorities and many others.
          </p>
        </Container>
      </Section>

      <section>
        <Container>
          <BigTextStyled>We turn great products into smart solutions!</BigTextStyled>
        </Container>
      </section>

      <Section>
        <Container>
          <SectionTitle>Contact us</SectionTitle>
          <p style={{ fontSize: '18px' }}>Have a question? Would you like to speak with us?</p>
          <Card>
            <CardDescription>
              Please fill out the form below and we will <br />
              contact you as soon as possible
            </CardDescription>
            <FormContact />
          </Card>
        </Container>
      </Section>
    </AboutStyled>
  );
}

export default About;
