import React from 'react';
import { VendorsWrapper } from './VendorsBlock.styled';
import LogoImage from '../LogoImage/LogoImage';
import cipia from '../../assets/images/vendors/cipia.png';
import cloudWise from '../../assets/images/vendors/cloud-wise.png';
import geotab from '../../assets/images/vendors/geotab.png';
import greenroad from '../../assets/images/vendors/greenroad.png';
import ituran from '../../assets/images/vendors/ituran.png';
import mobileye from '../../assets/images/vendors/mobileye.png';
import mix from '../../assets/images/vendors/mix.png';
import orpak from '../../assets/images/vendors/orpak.png';

const VendorsBlock = (): JSX.Element => (
  <VendorsWrapper>
    <LogoImage src={cipia} name="cipia" width="250px" />
    <LogoImage src={cloudWise} name="cloudWise" width="250px" />
    <LogoImage src={geotab} name="geotab" width="250px" />
    <LogoImage src={greenroad} name="greenroad" width="250px" />
    <LogoImage src={ituran} name="ituran" width="250px" />
    <LogoImage src={mobileye} name="mobileye" width="250px" />
    <LogoImage src={mix} name="mix" width="250px" />
    <LogoImage src={orpak} name="orpak" width="250px" />
  </VendorsWrapper>
);

export default VendorsBlock;
