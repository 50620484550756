import styled from 'styled-components';

export const CardWrapper = styled.div`
  position: relative;
  display: block;
  min-width: 100%;
  max-width: 1140px;

  background: #ffffff;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  margin: 0;
  padding: 32px;

  ${(props) => props.theme.media.tablet} {
    padding: 32px 72px;
  }
`;

export const CardDescription = styled.div`
  color: ${(props) => props.theme.palette.blueColor};

  font-size: 24px;
  font-weight: 700;
  text-align: center;

  margin-bottom: 24px;
`;

export const CardText = styled.div`
  color: ${(props) => props.theme.palette.darkBlueColor};

  font-size: 18px;
  margin-bottom: 24px;
`;
