import React from 'react';
import { ProductsStyled } from './Products.styled';
import Section from '../../components/Section/Section';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Container from '../../components/Container/Container';
import ProductCard from '../../components/ProductCard/ProductCard';
import { PageLeadStyled } from '../../components/PageTitle/PageLead.styled';
import mobileye8Image from '../../assets/images/mobileye/mobileye8.jpg';
import mobileyeShieldImage from '../../assets/images/mobileye/mobileye-shield.jpg';
import fleetSenseImage from '../../assets/images/fleet-fs10/header.jpg';
import iStickerImage from '../../assets/images/i-sticker/header.jpg';
import go9Image from '../../assets/images/go9/header.jpg';
import driveImage from '../../assets/images/drive/header.jpg';
import starlinkImage from '../../assets/images/companies/ituran.png';
import mixTrackingImage from '../../assets/images/mymix-tracking/header.jpg';
import mixManagerImage from '../../assets/images/mix-fleet-manager/header.jpg';
import mixVisionImage from '../../assets/images/mix-vision/header.jpg';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Products(): JSX.Element {
  return (
    <ProductsStyled>
      <SEO
        title="RGRauto | Products"
      />
      <Section style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle>Products</PageTitle>
          <PageLeadStyled>
            RGRauto is a leading global provider of integrated safety and optimization solutions for various-sized
            fleets in multiple industries.
          </PageLeadStyled>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Cipia</SectionTitle>
          <ProductCard
            title="Fleet Sense FS10"
            image={fleetSenseImage}
            imageAtLeft
            links={[
              {
                title: 'Read More',
                url: '/cipia/fleet-sense-fs10',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Cloud-Wise</SectionTitle>
          <ProductCard
            title="I-Sticker"
            image={iStickerImage}
            imageAtLeft={false}
            links={[
              {
                title: 'Read More',
                url: '/cloud-wise/i-sticker',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Geotab</SectionTitle>
          <ProductCard
            title="Go9"
            image={go9Image}
            imageAtLeft
            links={[
              {
                title: 'Read More',
                url: '/geotab/go9',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Greenroad</SectionTitle>
          <ProductCard
            title="Drive"
            image={driveImage}
            imageAtLeft={false}
            links={[
              {
                title: 'Read More',
                url: '/greenroad/drive',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
        </Container>
      </Section>

      {/*<Section style={{ paddingTop: '0' }}>*/}
      {/*  <Container>*/}
      {/*    <SectionTitle>Ituran</SectionTitle>*/}
      {/*    <ProductCard*/}
      {/*      title="Starlink"*/}
      {/*      image={starlinkImage}*/}
      {/*      imageAtLeft*/}
      {/*      links={[*/}
      {/*        {*/}
      {/*          title: 'Read More',*/}
      {/*          url: '/ituran',*/}
      {/*          type: 'nav',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Contact Us',*/}
      {/*          url: '/contact',*/}
      {/*          type: 'nav',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </Container>*/}
      {/*</Section>*/}

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>MiX Telematics</SectionTitle>
          <ProductCard
            title="MyMiX Tracking™"
            image={mixTrackingImage}
            imageAtLeft={false}
            links={[
              {
                title: 'Read More',
                url: '/mix-telematics/mix-tracking',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
          <ProductCard
            title="MiX Fleet Manager™"
            image={mixManagerImage}
            imageAtLeft
            links={[
              {
                title: 'Read More',
                url: '/mix-telematics/mix-fleet-manager',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
          <ProductCard
            title="MiX Vision ll"
            image={mixVisionImage}
            imageAtLeft={false}
            links={[
              {
                title: 'Read More',
                url: '/mix-telematics/mix-vision-ii',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Mobileye</SectionTitle>
          <ProductCard
            title="Mobileye 8 Connect"
            image={mobileye8Image}
            imageAtLeft
            links={[
              {
                title: 'Read More',
                url: '/mobileye/mobileye-8-connect',
                type: 'nav',
              },
              {
                title: 'Contact Us',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
          {/*<ProductCard*/}
          {/*  title="Mobileye Shield Plus"*/}
          {/*  image={mobileyeShieldImage}*/}
          {/*  imageAtLeft={false}*/}
          {/*  links={[*/}
          {/*    {*/}
          {/*      title: 'Read More',*/}
          {/*      url: '/mobileye/mobileye-shield',*/}
          {/*      type: 'nav',*/}
          {/*    },*/}
          {/*    {*/}
          {/*      title: 'Contact Us',*/}
          {/*      url: '/contact',*/}
          {/*      type: 'nav',*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
        </Container>
      </Section>
    </ProductsStyled>
  );
}

export default Products;
