import React from 'react';
import headerBackground from '../../assets/images/mix-fleet-manager/header.jpg';
import Header from '../../components/Header/Header';
import { AppTruckWrapper, CompliantBlock, MixFleetManagerStyled } from './MixFleetManager.styled';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import backgroundMap from '../../assets/images/mix-fleet-manager/map.png';
import backgroundCompliant from '../../assets/images/background/about-us-center.png';
import imageFleetManagerDescription from '../../assets/images/mix-fleet-manager/about.jpg';
import imageTruck from '../../assets/images/mix-fleet-manager/truck.png';
import imageComputer from '../../assets/images/mix-fleet-manager/pc.png';
import imageELD from '../../assets/images/mix-fleet-manager/eld.png';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixFleetManager(): JSX.Element {
  return (
    <MixFleetManagerStyled>
      <SEO
        title="RGRauto | MiX Fleet Manager"
      />
      <Header
        title={
          <>
            MiX Fleet Manager<sup>™</sup>
          </>
        }
        description="MiX Fleet Manager™ offers fleet operators unlimited access to information about their vehicles and drivers, with a host of features, tools and reports to help maximize return on investment."
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock
            image={imageFleetManagerDescription}
            imagePosition="left"
            imageWidth={534}
            style={{ padding: '8px 16px' }}
          >
            <p style={{ fontSize: '16px', padding: '0 16px' }}>
              Fleet management solutions that offer fleet operators unlimited access to information about their vehicles
              and drivers, with multiple features, tools and reports to help maximize return on investment.
            </p>
            <p style={{ fontSize: '16px', padding: '0 16px' }}>
              The solution includes a sophisticated on-board computer that collects and transmits valuable vehicle and
              driver data. This data is stored in one of several top-tier data centers and the information is available
              online or via a mobile app (Android or iOS).
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section backgroundColor="#11357E" backgroundImage={backgroundMap} style={{ backgroundSize: 'contain' }}>
        <Container>
          <SectionDescription>
            <p style={{ color: '#ffffff' }}>
              With the MyMiX Tracking™ app, you can maintain visibility of non-permanent fleet vehicles and contractors
              and monitor risky driver behavior – no hardware installation needed!
            </p>
          </SectionDescription>
          <AppTruckWrapper>
            <div>
              <img src={imageTruck} height="400px" alt="imageTruck" />
            </div>
            <div>
              <img src={imageComputer} height="400px" alt="imageComputer" />
            </div>
          </AppTruckWrapper>
        </Container>
      </Section>

      <Section style={{ paddingBottom: '0' }}>
        <Container>
          <SectionTitle>Electronic Logging Device (ELD)</SectionTitle>
          <p>
            MiX Telematics’s ELD (electronic logging device) has been specifically designed to manage and maintain your
            fleet’s compliance with the Hours of Service (HOS) rule through electronically tracking driver’s Record of
            Duty Status (RODS) as an effective replacement for outdated paper logbooks.
          </p>
          <p style={{ textAlign: 'center' }}>
            <img src={imageELD} alt="ELD" height="517px" style={{ width: '100%', height: 'auto', maxWidth: '900px' }} />
          </p>
        </Container>
      </Section>

      <Section backgroundImage={backgroundCompliant} style={{ padding: '100px 0', backgroundSize: 'contain' }}>
        <Container>
          <CompliantBlock>
            <SectionTitle>Are you compliant with the ELD Mandate?</SectionTitle>
            <p>
              On December 18 2017, the ELD Mandate came into effect, as instituted by the Federal Motor Carrier Safety
              Administration (FMCSA). The Mandate requires commercial motor vehicle drivers to track their Record of
              Duty Status (RODS) electronically, using compliant Electronic Logging Devices (ELD) versus paper logbooks.
              Act now to make sure your fleet is fully compliant by the final deadline of December 16, 2019!
            </p>
          </CompliantBlock>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MixFleetManagerStyled>
  );
}

export default MixFleetManager;
