// @see https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/

export const disableBodyScroll = (): void => {
  const body = document.getElementsByTagName('body')[0];

  body.style.height = '100vh';
  body.style.overflowY = 'hidden';
  body.style.paddingRight = '15px';
};

export const enableBodyScroll = (): void => {
  const body = document.getElementsByTagName('body')[0];

  body.style.height = 'initial';
  body.style.overflowY = 'initial';
  body.style.paddingRight = 'initial';
};
