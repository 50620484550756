const industryList: string[] = [
  'Agriculture',
  'Airlines, Airports & Air Services',
  'Automotive',
  'Construction',
  'Distribution',
  'Field Sales & Services',
  'Food & Beverage/FMCG',
  'Freight/Trucking & Logistics Services',
  'Government & Local Authorities',
  'Healthcare & Pharmaceutical',
  'Insurance',
  'Leasing & Financial Services',
  'Moving & Storage',
  'Oil, Gas & Mining',
  'Passenger Transportation',
  'Police & First Responders',
  'Professional Services',
  'Public Transport',
  'Real Estate',
  'Telecommunications',
  'Utilities',
  'Waste Management',
  'Wholesale/Retail',
];

export default industryList;
