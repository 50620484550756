import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';

const PrivacyPolicyModal = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Privacy Policy"
      onClose={() => {
        modal.remove();
      }}
    >
      <p />
    </Modal>
  );
});

export default PrivacyPolicyModal;
