import styled, { css } from 'styled-components';
import { SectionStyledProps } from './Section.types';

export const SectionStyled = styled.section<SectionStyledProps>`
  position: relative;
  z-index: 1;
  padding: 48px 0;
  margin: 0 auto;
  background-color: ${(props) => props.backgroundColor || '#FFFFFF'};

  ${(props) =>
    props.backgroundImage !== null &&
    css`
      background-image: url(${props.backgroundImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `};

  ${(props) => props.theme.media.desktop} {
    padding: 72px 0;
  }
`;
