import React from 'react';
import {
  ModalBackdrop,
  ModalCloseButton,
  ModalContainer,
  ModalContent,
  ModalRoot,
  ModalTitle,
  ModalWrapper,
} from './Modal.styled';

type ModalProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  variant?: 'white' | 'blue';
};
const Modal = ({ title, children, onClose, variant = 'blue' }: ModalProps): JSX.Element => (
  <ModalRoot role="presentation">
    <ModalBackdrop aria-hidden />
    <ModalContainer role="presentation" tabIndex={-1} onClick={onClose}>
      <ModalWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        variant={variant}
      >
        <ModalTitle>{title} </ModalTitle>
        <ModalContent>{children}</ModalContent>
        <ModalCloseButton variant={variant} onClick={onClose} type="button" />
      </ModalWrapper>
    </ModalContainer>
  </ModalRoot>
);
Modal.defaultProps = {
  variant: 'blue',
};

export default Modal;
