import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { IturanStyled } from './Ituran.styled';
import Container from '../../components/Container/Container';
import { CardText } from '../../components/Card/Card.styled';
import Card from '../../components/Card/Card';
import FormRequest from '../../components/Form/FormRequest';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import companyLogo from '../../assets/images/logo/ituran.jpg';
import companyImage from '../../assets/images/companies/ituran.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Ituran(): JSX.Element {
  return (
    <IturanStyled>
      <SEO
        title="RGRauto | Ituran"
      />
      <Container>
        <PageTitle>Ituran</PageTitle>
        <CompanyCard
          name="Ituran"
          logo={companyLogo}
          image={companyImage}
          buttons={[]}
          // buttons={[
          //   {
          //     title: 'About StarLink™',
          //     url: '/ituran/starlink',
          //   },
          // ]}
        >
          <p>
            Ituran
          </p>
          <p>
            StarLink™
          </p>
        </CompanyCard>
        <Card>
          <SectionTitle>Request a demo</SectionTitle>
          <CardText>
            To request a demo, please complete the form below <br />
            and we will contact you as soon as possible
          </CardText>
          <FormRequest />
        </Card>
      </Container>
    </IturanStyled>
  );
}

export default Ituran;
