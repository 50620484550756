import styled from 'styled-components';
import { LogoImageStyled } from '../LogoImage/LogoImage.styled';

export const FlexWrapper = styled.div`
  display: flex;
`;

export const MainBlock = styled.div`
  flex: 0 1 auto;
  width: auto;

  hr {
    border: 0;
    border-top: 2px solid #bcbcbc;
    margin: 24px 0;
  }

  ${LogoImageStyled} {
    text-align: left;
  }
`;

export const ImageBlock = styled.div<{ image: string }>`
  display: none;

  ${(props) => props.theme.media.desktop} {
    display: block;
    flex: 1 0 500px;
    width: 500px;

    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }
`;

export const ProductButtonsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  ${(props) => props.theme.media.tablet} {
    margin: 24px auto;

    &::before,
    &::after {
      position: absolute;
      content: '';
      background: ${(props) => props.theme.palette.greenColor};
      height: 2px;
      width: 108px;

      bottom: 49%;
    }
    &::before {
      left: -124px;
    }
    &::after {
      right: -124px;
    }
  }

  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
  }
`;

export const ProductLinkWrapper = styled.div`
  flex: 0 1 auto;
  margin: 8px;

  a {
    transition: all 0.15s ease;
    text-decoration: none;

    display: inline-block;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.palette.blueColor};
    outline: none;

    height: 40px;
    min-width: 220px;

    cursor: pointer;

    color: ${(props) => props.theme.palette.blueColor};
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;

    padding: 0 24px;

    &:hover {
      color: #ffffff;
      background: ${(props) => props.theme.palette.blueColor};
    }
  }
`;
