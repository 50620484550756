import styled from 'styled-components';
import { Container } from '../../components/Container/Container.styled';
import backgroundTop from '../../assets/images/background/fleet-top.png';
import backgroundCenter from '../../assets/images/background/fleet-center.png';

export const FleetTelematicsStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;

  padding-bottom: 8px;

  ${Container} {
    background-image: url(${backgroundTop}), url(${backgroundCenter});
    background-repeat: no-repeat;
    background-position: top right, center;
    background-size: 850px auto, 100% auto;
  }
`;

export const LogoWrapper = styled.div`
  background: #ffffff;

  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    div {
      flex: 0 0 auto;
    }
  }

  ${(props) => props.theme.media.desktop} {
    > div:first-child {
      justify-content: space-between;
    }
  }
`;
