import styled from 'styled-components';

export const IndustriesStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const IndustriesCardsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
  }
`;
