import React from 'react';
import { CSSObject } from 'styled-components';
import { SectionDescriptionStyled } from './SectionDescription.styled';

type SectionDescriptionProps = {
  children: JSX.Element | JSX.Element[];
  style?: CSSObject;
};
const SectionDescription = ({ style = {}, children }: SectionDescriptionProps): JSX.Element => (
  <SectionDescriptionStyled style={style}>{children}</SectionDescriptionStyled>
);
SectionDescription.defaultProps = {
  style: {},
};

export default SectionDescription;
