import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import VideoBlock from '../../components/VideoBlock/VideoBlock';

const VideoModal = NiceModal.create(({ title, videoCode }: { title: string; videoCode: string }) => {
  const modal = useModal();
  return (
    <Modal
      title={title}
      onClose={() => {
        modal.remove();
      }}
    >
      <VideoBlock code={videoCode} />
    </Modal>
  );
});

export default VideoModal;
