import React from 'react';
import { EdgeBlockStyled, EdgeContentWrapper, EdgeDescription, EdgeIcon, EdgeTitle } from './EdgeBlock.styled';
import { EdgeBlockProps } from './EdgeBlock.types';

const EdgeBlock = ({ icon, title, description }: EdgeBlockProps): JSX.Element => (
  <EdgeBlockStyled>
    <EdgeIcon>
      <img src={icon} alt={title} width="60px" />
    </EdgeIcon>
    <EdgeContentWrapper>
      <EdgeTitle>{title}</EdgeTitle>
      <EdgeDescription>{description}</EdgeDescription>
    </EdgeContentWrapper>
  </EdgeBlockStyled>
);

export default EdgeBlock;
