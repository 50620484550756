import styled from "styled-components";

export const CookieBannerStyled = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: #ffffff;
  width: 100%;
  
  border-top: 1px solid ${(props) => props.theme.palette.darkBlueColor};
`;

export const CookieBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  
  > div {
    margin: 8px;
  }
`;