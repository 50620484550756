import styled from 'styled-components';

export const BigTextStyled = styled.div`
  position: relative;
  color: ${(props) => props.theme.palette.darkBlueColor};

  font-size: 48px;
  line-height: 1.2;
  font-weight: 700;

  padding: 24px 0;

  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;

  text-align: center;
`;
