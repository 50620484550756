import styled from 'styled-components';
import selectDropdown from '../../assets/icons/form-select.svg';
import checkboxIcon from '../../assets/icons/form-checkbox.svg';

export const FormStyled = styled.form`
  margin: 0 auto;
`;

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  flex: 0 0 auto;
  width: auto;

  padding: 8px;

  ${(props) => props.theme.media.tablet} {
    flex: 0 0 50%;
    width: 50%;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 24px;
  }
`;

export const FormField = styled.div`
  margin-bottom: 16px;
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  line-height: 1;
  color: ${(props) => props.theme.palette.errorColor};

  margin: 4px 0 0;
  height: 10px;
`;

export const FormControl = styled.input`
  display: block;

  width: 100%;
  max-width: 100%;
  min-height: 53px;

  font-size: 18px;
  line-height: 1;
  padding: 16px 24px;

  background: ${(props) => props.theme.palette.greyColor};
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  appearance: none;

  transition: border-color 0.15s ease-in-out;

  &:focus {
    border-color: ${(props) => props.theme.palette.greenColor};
  }

  &::placeholder {
    font-size: 18px;
    line-height: 1;
    color: ${(props) => props.theme.palette.placeholderColor};
    text-transform: uppercase;
    opacity: 1;
  }
`;

export const FormSelect = styled.select`
  display: block;

  width: 100%;
  max-width: 100%;
  min-height: 53px;

  font-size: 18px;
  line-height: 1;
  padding: 16px 24px;

  background-color: ${(props) => props.theme.palette.greyColor};
  border-radius: 5px;
  border: 2px solid transparent;
  outline: none;
  appearance: none;

  transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;

  background-image: url(${selectDropdown});
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 10px auto;

  color: ${(props) => props.theme.palette.placeholderColor};
  text-transform: uppercase;

  &:focus {
    border-color: ${(props) => props.theme.palette.greenColor};
    color: ${(props) => props.theme.palette.textColor};
  }

  option {
    color: ${(props) => props.theme.palette.textColor};
  }

  option:first-child {
    display: none;
  }
`;

export const FormCheckbox = styled.div`
  display: block;
  min-height: 22px;
  padding-left: 24px;

  input {
    margin-left: -24px;

    width: 18px;
    height: 18px;
    margin-top: 3px; // Magick number forever
    vertical-align: top;

    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    border: 1px solid ${(props) => props.theme.palette.placeholderColor};
    border-radius: 5px;

    appearance: none;
    color-adjust: exact;

    &:checked[type='checkbox'] {
      border-color: ${(props) => props.theme.palette.greenColor};
      background-image: url(${checkboxIcon});
    }
  }

  label {
    display: inline-block;
    font-size: 12px;
    color: #838383;
    line-height: 18px;

    padding-left: 8px;
  }
`;

export const FormButton = styled.button`
  transition: all 0.15s ease;

  display: block;
  
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.blueColor};
  outline: none;

  height: 40px;
  width: 220px;

  cursor: pointer;

  color: #ffffff;
  background-color: ${(props) => props.theme.palette.blueColor};
  
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  &:hover:enabled {
    color: ${(props) => props.theme.palette.blueColor};
    background-color: #ffffff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    color: ${(props) => props.theme.palette.blueColor};
    background-color: #ffffff;
  }
`;
