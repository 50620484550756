import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { DeviceBlock, DeviceBlockLink, DeviceBlockTitle, DevicesWrapper, MixVisionStyled } from './MixVision.styled';
import headerBackground from '../../assets/images/mix-vision/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import imageDashboardCameras from '../../assets/images/mix-vision/modal_1-1.png';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import MixVisionModal01 from './Modal01';
import MixVisionModal02 from './Modal02';
import MixVisionModal03 from './Modal03';
import MixVisionModal04 from './Modal04';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import { disableBodyScroll } from '../../lib/scrollLock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixVision(): JSX.Element {
  const modal01 = useModal(MixVisionModal01);
  const modal02 = useModal(MixVisionModal02);
  const modal03 = useModal(MixVisionModal03);
  const modal04 = useModal(MixVisionModal04);
  const openModal = (id: number) => {
    disableBodyScroll();
    switch (id) {
      case 1:
        modal01.show().catch((error) => console.log(error));
        break;
      case 2:
        modal02.show().catch((error) => console.log(error));
        break;
      case 3:
        modal03.show().catch((error) => console.log(error));
        break;
      case 4:
        modal04.show().catch((error) => console.log(error));
        break;
      default:
        break;
    }
  };

  return (
    <MixVisionStyled>
      <SEO
        title="RGRauto | MiX Vision II"
      />
      <Header
        title="MiX Vision II"
        description="Use AI dashboard cameras to see activity on the road and inside the vehicle. Get immediate alerts to the driver and your business to shed light on unsafe driving behavior, lower fleet risk, assist with accident analysis, reduce insurance and protect valuable cargo."
        backgroundImage={headerBackground}
      />

      <Section style={{ paddingBottom: '0' }}>
        <Container>
          <BorderBlock image={imageDashboardCameras} imagePosition="right" imageWidth={320} imageBackground="#ffffff">
            <p>
              Drivers and fleet managers get real-time alerts for risky driving with road- and in-cab facing integrated
              AI dash cams that help improve safety and operational efficiency.{' '}
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Detect / Prevent</SectionTitle>
          <p>
            Road-facing and in-cab facing cameras detect and prevent collisions and risky driver behavior keeping you
            connected, and your drivers and vehicles protected.
          </p>
          <DevicesWrapper>
            <DeviceBlock>
              <DeviceBlockTitle>Road-Facing Camera</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(1);
                  }}
                  type="button"
                >
                  Learn more
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
            <DeviceBlock>
              <DeviceBlockTitle>Driving-Facing Camera</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(2);
                  }}
                  type="button"
                >
                  Learn more
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
            <DeviceBlock>
              <DeviceBlockTitle>In-Cab Facing Camera</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(3);
                  }}
                  type="button"
                >
                  Learn more
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
            <DeviceBlock>
              <DeviceBlockTitle>AI Driving Coach</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(4);
                  }}
                  type="button"
                >
                  Learn more
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
          </DevicesWrapper>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MixVisionStyled>
  );
}

export default MixVision;
