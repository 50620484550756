import styled from 'styled-components';

export const SectionDescriptionStyled = styled.div`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0.02pt;

  position: relative;
  margin-top: 32px;

  &::before {
    position: absolute;
    content: '';
    top: -24px;
    left: 0;
    background: ${(props) => props.theme.palette.greenColor};
    height: 4px;
    width: 64px;
  }
`;
