import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { CaptchaStyled } from './Captcha.styled';

// Captcha
const getRandomNumber = () => {
  const rand = 1 + Math.random() * 9;
  return Math.floor(rand);
};
const A = getRandomNumber();
const B = getRandomNumber();

type OnVerifyFunction = (a: boolean) => void;
const Captcha = ({ onVerify }: { onVerify: OnVerifyFunction }): JSX.Element => {
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);

  useEffect(() => {
    onVerify(isCaptchaCorrect);
  });

  const verifyCaptcha = (e: BaseSyntheticEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setIsCaptchaCorrect(A + B === parseInt(e.target?.value, 10));
  };

  return (
    <CaptchaStyled>
      <label htmlFor="captcha">{`${A} + ${B} = `}</label>
      <input type="text" placeholder="" autoComplete="0" name="captcha" onInput={verifyCaptcha} />
    </CaptchaStyled>
  );
};

export default Captcha;
