import styled from 'styled-components';

export const PageTitle = styled.h1<{ variant?: string }>`
  position: relative;
  color: ${(props) => (props.variant !== 'inverted' ? props.theme.palette.textColor : '#FFFFFF')};

  font-size: 36px;
  line-height: 1.4;
  font-weight: 700;

  padding: 32px 0 0;
  margin: 0 0 48px;

  &::after {
    position: absolute;
    content: '';
    bottom: -24px;
    left: 0;
    background-color: ${(props) => (props.variant !== 'inverted' ? props.theme.palette.greenColor : '#FFFFFF')};
    height: 4px;
    width: 64px;
  }
`;

export const SectionTitle = styled.h2<{ variant?: string }>`
  position: relative;
  color: ${(props) => (props.variant !== 'inverted' ? props.theme.palette.textColor : '#FFFFFF')};

  font-size: 36px;
  line-height: 1.4;
  font-weight: 700;

  padding: 32px 0 0;
  margin: 0 0 32px;

  &::after {
    position: absolute;
    content: '';
    bottom: -12px;
    left: 0;
    background-color: ${(props) => (props.variant !== 'inverted' ? props.theme.palette.greenColor : '#FFFFFF')};
    height: 4px;
    width: 64px;
  }
`;
