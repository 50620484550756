import styled from 'styled-components';

export const PageLeadStyled = styled.p`
  position: relative;
  color: ${(props) => props.theme.palette.textColor};

  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;

  padding: 32px 0 24px 0;
  margin: 0 0 24px;

  border-bottom: 1px solid #aaaaaa;
`;
