import styled from 'styled-components';

export const Icon = styled.img`
  height: 125px;
  width: auto;
`;

export const Title = styled.div`
  position: relative;
  color: ${(props) => props.theme.palette.textColor};
  transition: color 0.15s ease;

  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;

  padding: 32px 0 0;
  margin: 0 0 32px;

  &::after {
    position: absolute;
    content: '';
    bottom: -12px;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.palette.greenColor};
    height: 2px;
    width: 64px;
    margin: 0 auto;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  line-height: 1.4;
  color: ${(props) => props.theme.palette.textColor};
  transition: color 0.15s ease;
`;

export const FeatureCardWrapper = styled.div`
  max-width: 320px;
  text-align: center;

  padding: 32px;

  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  transition: all 0.15s ease;

  &:hover {
    background-color: ${(props) => props.theme.palette.blueColor};
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.25);

    ${Title},
    ${Description} {
      color: #ffffff;
    }
  }
`;
