import React from 'react';
import {
  ButtonBlockContent,
  ButtonBlockImage,
  ButtonBlockWrapper,
  FaceBlockContent,
  FaceBlockWrapper,
  GreenroadVideosenseStyled,
  PhoneBlockBackground,
  PhoneBlockContentWrapper,
  PhoneBlockWrapper,
  RoadBlock,
} from './GreenroadVideosense.styled';
import headerBackground from '../../assets/images/videosense/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import Card from '../../components/Card/Card';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import imageVideosenseDescription from '../../assets/images/videosense/video-sense.jpg';
import imagePhone from '../../assets/images/videosense/phone.png';
import imageButton from '../../assets/images/videosense/engine-start-button.png';
import imageButtonDescription from '../../assets/images/videosense/engine-starting.jpg';
import imageFaceIcon from '../../assets/images/videosense/record-icon.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function GreenroadVideosense(): JSX.Element {
  return (
    <GreenroadVideosenseStyled>
      <SEO
        title="RGRauto | GreenRoad Videosense"
      />
      <Header
        title={
          <>
            Objective In-Vehicle
            <br />
            Feedback
          </>
        }
        description={
          <>
            Real-Time Safety Feedback & Coaching
            <br />
            Eliminates Bad Habits and Risky Driving Behavior
          </>
        }
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock
            image={imageVideosenseDescription}
            imagePosition="right"
            imageWidth={518}
            style={{ padding: '0 16px' }}
          >
            <p>
              VideoSense™’s smart video cameras and AI (Artificial Intelligence) work together to identify fatigue,
              distraction, seat belt usage and other driver behaviors, with unsafe states triggering real-time alerts to
              fleet managers and drivers.
            </p>
            <p>
              This enables in-the-moment intervention and behavioral change to prevent disaster. GreenRoad video cameras
              also provide intelligent input to support additional fleet management applications.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section style={{ padding: '0' }}>
        <Container>
          <PhoneBlockWrapper>
            <PhoneBlockContentWrapper>
              <div>
                <img src={imagePhone} alt="Phone pic" width="190" />
              </div>
              <div>
                <h2>Simple Metric = Big Impact On Driving Safety</h2>
                <div>
                  <p>
                    <strong>
                      VideoSense™ uses vehicle-installed video cameras to automatically document safety events
                      identified by the GreenRoad Safety Telematics Platform, giving you deep context for root cause
                      analysis.
                    </strong>
                  </p>
                  <p>
                    The software adds another dimension of insight to GreenRoad driver behavior data and safety scores,
                    and serves as a DIM (Driver Impairment Monitoring) solution to enable proactive intervention in the
                    case of driver fatigue or distraction.
                  </p>
                </div>
              </div>
            </PhoneBlockContentWrapper>
            <PhoneBlockBackground />
          </PhoneBlockWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <FaceBlockWrapper>
            <FaceBlockContent>
              <div>
                <img src={imageFaceIcon} alt="icon" height="120px" />
              </div>
              <div>
                <p>
                  The VideoSense™ solution automatically records the critical seconds directly before, during, and after
                  each safety event, providing fleet managers with “one-link”access to the video directly from the
                  event’s telematics record.
                </p>
              </div>
            </FaceBlockContent>
          </FaceBlockWrapper>
          <p>
            This enables clear correlation of external road factors with driving behaviors to exonerate your drivers
            and/or understand what actions might have been driver-correctable. These videos are also invaluable training
            tools.
          </p>
        </Container>
      </Section>

      <Section style={{ padding: '0' }}>
        <Container>
          <SectionDescription>
            <p>
              In addition, the technology can recognise issues with fatigue, distraction, seatbelt usage, smoking etc.,
              triggering automatic alarms that are delivered directly to the drivers and their fleet managers.
            </p>
          </SectionDescription>
        </Container>
        <RoadBlock />
        <Container>
          <ButtonBlockWrapper>
            <ButtonBlockImage src={imageButton} alt="Videosense button" />
            <ButtonBlockContent>
              Since VideoSense™ cameras work continuously, their footage can be used as input for automated management
              alerts based on driver behavior, enabling proactive real-time intervention at signs of distraction or
              fatigue.
            </ButtonBlockContent>
          </ButtonBlockWrapper>
          <BorderBlock image={imageButtonDescription} imagePosition="left" imageWidth={530} style={{ padding: '16px' }}>
            <p style={{ padding: '0 16px' }}>
              They can be activated to provide a live, real-time feed from the vehicle, including by the driver
              independently by pressing an inconspicuous Driver Button that drivers can use in times of stress or
              danger.
            </p>
            <p style={{ padding: '0 16px' }}>
              In addition, the software can support Start Inhibit capabilities for added protection in emergency
              situations.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </GreenroadVideosenseStyled>
  );
}

export default GreenroadVideosense;
