import React from 'react';
import { Description, Divider, Icon, Link, Number, NumberBlockWrapper, NumberWrapper } from './NumberBlock.styled';
import { NumberBlockProps } from './NumberBlock.types';

const NumberBlock = ({ icon, number, percents, description, link }: NumberBlockProps): JSX.Element => {
  let divider;
  if (percents) {
    divider = (
      <Divider>
        <span style={{ width: `${number}%` }} />
        <span style={{ width: `${99 - number}%` }} />
      </Divider>
    );
  } else {
    divider = (
      <Divider>
        <span style={{ width: '100%' }} />
      </Divider>
    );
  }

  return (
    <NumberBlockWrapper>
      <NumberWrapper>
        <Icon src={icon} />
        <Number>
          {number}
          {percents ? '%' : ''}
        </Number>
      </NumberWrapper>
      {divider}
      <Description>{description}</Description>
      {link && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link>
          <a href={link.url}>{link.title}</a>
        </Link>
      )}
    </NumberBlockWrapper>
  );
};

export default NumberBlock;
