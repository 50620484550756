import styled from 'styled-components';
import functionsBg from '../../assets/images/fleet-fs10/functions.jpg';

export const CipiaFleetSenseStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;

  padding-bottom: 8px;
`;

export const FunctionsBlock = styled.div`
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;

  ${(props) => props.theme.media.tablet} {
    background-image: url(${functionsBg});
  }
`;

export const FunctionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  ${(props) => props.theme.media.tablet} {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Function = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;

  width: 250px;
  margin: 16px;

  img {
    height: 97px;
    width: 97px;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    margin-left: 16px;
  }
`;
