import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemDescription,
  AccordionItemIcon,
  AccordionItemNameWrapper,
  AccordionItemTitle,
  AlertsSwitcherWrapper,
  Video,
  VideoWrapper,
} from './AlertsSwitcher.styled';
import signalIcon01 from '../../assets/images/mobileye8/signal-icon-01.png';
import signalIcon02 from '../../assets/images/mobileye8/signal-icon-02.png';
import signalIcon03 from '../../assets/images/mobileye8/signal-icon-03.png';
import signalIcon04 from '../../assets/images/mobileye8/signal-icon-04.png';
import signalIcon05 from '../../assets/images/mobileye8/signal-icon-05-USA.png';
import signalVideo01 from '../../assets/video/mobileye8-signal-01.mp4';
import signalVideo02 from '../../assets/video/mobileye8-signal-02.mp4';
import signalVideo03 from '../../assets/video/mobileye8-signal-03.mp4';
import signalVideo04 from '../../assets/video/mobileye8-signal-04.mp4';
import signalVideo05 from '../../assets/video/mobileye8-signal-05.mp4';
import { AlertSwitcherItemProps } from './AlertsSwitcher.types';

const alertItems = [
  {
    title: 'Forward Collision Warning - FCW',
    description:
      'The System sends a visual and audio alert when it detects an imminent collision with a vehicle or motorcycle ahead, both on highways and in urban areas. The System issues an alert up to 2.5 seconds before a collision, allowing time to react.',
    icon: signalIcon01,
    video: signalVideo01,
  },
  {
    title: 'Pedestrian & Cyclist Collision Warning (PCW)',
    description:
      'The System sends a visual and audio alert before an imminent collision with a pedestrian or cyclist, allowing the driver time to react. The Time to Collision (TTC) is calculated and when it drops below 2 seconds, a PCW is generated.',
    icon: signalIcon02,
    video: signalVideo02,
  },
  {
    title: 'Lane Departure Warning (LDW)',
    description: 'The System sends a visual and audio alert when there is a lane deviation without signaling.',
    icon: signalIcon03,
    video: signalVideo03,
  },
  {
    title: 'Headway Monitoring & Warning (HMW)',
    description:
      'HMW monitors the distance (measured in seconds) from the vehicle ahead when that time is 2.5 seconds or less, and issues an alert if the distance is below or equal to a pre-defined threshold.',
    icon: signalIcon04,
    video: signalVideo04,
  },
  {
    title: 'Speed Limit Indicator (SLI) & Traffic-Signs Recognition (TSR)',
    description:
      'Mobileye’s speed limit indicator scans for speed limit signs along the road. When a driver exceeds the speed limit, a visual indicator alerts them - a helpful reminder for the driver to maintain a safe speed.',
    icon: signalIcon05,
    video: signalVideo05,
  },
];

const AlertSwitcherItem = ({ title, description, icon, isActive, onClick }: AlertSwitcherItemProps): JSX.Element => (
  <AccordionItem onClick={onClick} isActive={isActive}>
    <AccordionItemNameWrapper>
      <AccordionItemTitle>{title}</AccordionItemTitle>
      <AccordionItemDescription>{description}</AccordionItemDescription>
    </AccordionItemNameWrapper>
    <AccordionItemIcon>
      <img width="100" src={icon} alt={title} />
    </AccordionItemIcon>
  </AccordionItem>
);

const AlertsSwitcher = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderedAlerts = alertItems.map((item, index) => (
    <AlertSwitcherItem
      title={item.title}
      description={item.description}
      icon={item.icon}
      isActive={index === activeIndex}
      onClick={() => {
        setActiveIndex(index);
      }}
      // eslint-disable-next-line react/no-array-index-key
      key={index}
    />
  ));

  return (
    <AlertsSwitcherWrapper>
      <Accordion>{renderedAlerts}</Accordion>
      <Video>
        <VideoWrapper>
          <video src={alertItems[activeIndex].video} muted autoPlay playsInline loop />
        </VideoWrapper>
      </Video>
    </AlertsSwitcherWrapper>
  );
};

export default AlertsSwitcher;
