import React from 'react';
import { NavLink } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { Column, Copyright, Flexbox, FooterLink, FooterWrapper } from './Footer.styled';
import Container from '../Container/Container';
import logo from '../../assets/images/logo-footer.png';
import EndUserAgreementModal from '../../modals/EndUserAgreementModal/EndUserAgreementModal';
import PrivacyPolicyModal from '../../modals/PrivacyPolicyModal/PrivacyPolicyModal';

const Footer = (): JSX.Element => {
  const endUserAgreementModal = useModal(EndUserAgreementModal);
  const privacyPolicyModal = useModal(PrivacyPolicyModal);

  return (
    <footer>
      <FooterWrapper>
        <Container>
          <Flexbox>
            <Column>
              <div>
                <NavLink exact to="/">
                  <img src={logo} alt="RGRauto" title="RGRauto" width="auto" height="46" />
                </NavLink>
              </div>
              <div>
                <FooterLink exact to="/solutions">
                  Solutions
                </FooterLink>
              </div>
              <div>
                <FooterLink exact to="/products">
                  Products
                </FooterLink>
              </div>
            </Column>
            <Column>
              <div>
                <FooterLink exact to="/industries">
                  Industries
                </FooterLink>
              </div>
              <div>
                <FooterLink exact to="/demo">
                  Request a Demo
                </FooterLink>
              </div>
              <div>
                <FooterLink as="a">Support</FooterLink>
              </div>
            </Column>
            <Column>
              <div>
                <FooterLink exact to="/about">
                  About Us
                </FooterLink>
              </div>
              <div>
                <FooterLink as="a" onClick={() => privacyPolicyModal.show()}>
                  Privacy Policy
                </FooterLink>
              </div>
              <div>
                <FooterLink as="a" onClick={() => endUserAgreementModal.show()}>
                  End User Agreement
                </FooterLink>
              </div>
            </Column>
            <Column>
              <div>
                <FooterLink exact to="/contact">
                  Contact Us
                </FooterLink>
              </div>
              <div>
                <FooterLink exact to="/sitemap">
                  Sitemap
                </FooterLink>
              </div>
              <div>
                <FooterLink as="a">Login</FooterLink>
              </div>
            </Column>
          </Flexbox>
        </Container>
      </FooterWrapper>
      <Copyright>
        <Container>
          <p>© 2003-{new Date().getFullYear()} RGRAuto. All Rights Reserved.</p>
        </Container>
      </Copyright>
    </footer>
  );
};

export default Footer;
