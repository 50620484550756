import React from 'react';
import { ContactsIcon, ContactsInfo, ContactsWrapper, MapImg } from './ContactsBlock.styled';
import emailIcon from '../../assets/icons/email.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import clockIcon from '../../assets/icons/clock.svg';
import mapImage from '../../assets/images/contact-us/map.png';

const ContactsBlock = (): JSX.Element => (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <ContactsWrapper>
      <ContactsIcon>
        <img src={emailIcon} alt="Email" />
      </ContactsIcon>
      <ContactsInfo>
        <div>
          <a href="mailto:sales@rgrauto.com">sales@rgrauto.com</a>
        </div>
        <div>
          <a href="mailto:support@rgrauto.com">support@rgrauto.com</a>
        </div>
        <div>
          <a href="mailto:info@rgrauto.com">info@rgrauto.com</a>
        </div>
      </ContactsInfo>
    </ContactsWrapper>
    <ContactsWrapper>
      <ContactsIcon>
        <img src={phoneIcon} alt="Phone" />
      </ContactsIcon>
      <ContactsInfo>
        <div>
          <a href="tel:833-747-2886">833-747-2886</a>
        </div>
      </ContactsInfo>
    </ContactsWrapper>
    <ContactsWrapper>
      <ContactsIcon>
        <img src={clockIcon} alt="Time" />
      </ContactsIcon>
      <ContactsInfo>
        <div>
          <strong>Monday-Thursday:</strong> 9AM to 5:30PM ET
        </div>
        <div>
          <strong>Friday:</strong> 9AM to 4:30PM ET
        </div>
      </ContactsInfo>
    </ContactsWrapper>
    <div style={{ marginTop: 'auto', marginBottom: '24px' }}>
      <p>
        <strong>
          20801 Biscayne Blvd. Suite 403, <br />
          Aventura FL 33180
        </strong>
      </p>
      <div>
        <a href="https://g.page/rgrauto-inc?share" target="_blank" rel="noreferrer">
          <MapImg src={mapImage} alt="map" />
        </a>
      </div>
    </div>
  </div>
);

export default ContactsBlock;
