import styled from 'styled-components';
import { Container } from '../../components/Container/Container.styled';
import backgroundTop from '../../assets/images/background/dms-top.png';
import backgroundCenter from '../../assets/images/background/dms-center.png';
import { LogoImageStyled } from '../../components/LogoImage/LogoImage.styled';

export const DriverMonitoringSystemStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;

  padding-bottom: 8px;

  ${Container} {
    background-image: url(${backgroundTop}), url(${backgroundCenter});
    background-repeat: no-repeat;
    background-position: right top, center 420px;
    background-size: 850px auto, 100% auto;
  }
`;

export const SystemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  > div {
    flex: 0 0 100%;
    width: 100%;

    padding-bottom: 36px;
  }

  ${(props) => props.theme.media.tablet} {
    > div {
      flex: 0 0 30%;
      width: 30%;
      max-width: 380px;

      & + div {
        margin-left: 30px;
      }
    }
  }

  hr {
    border-top: 1px solid ${(props) => props.theme.palette.darkBlueColor};
  }

  ${LogoImageStyled} {
    margin: 32px 0;
  }
`;
