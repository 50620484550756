import React from 'react';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import headerBackground from '../../assets/images/go9/header.jpg';
import aboutImage from '../../assets/images/go9/about.png';
import go9Image from '../../assets/images/go9/go9.png';
import { GeotabGo9Styled } from './GeotabGo9.styled';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function GeotabGo9(): JSX.Element {
  return (
    <GeotabGo9Styled>
      <SEO
        title="RGRauto | Geotab GO9"
      />
      <Header
        title="Geotab"
        description={
          <>
            Vehicle tracking device for businesses
            <br /> and commercial vehicles.
          </>
        }
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock
            image={aboutImage}
            imagePosition="left"
            imageWidth={640}
            imageOffset={{ left: -60 }}
            imageBackground={null}
          >
            <p>
              The Geotab GO9 is a small form-factor vehicle tracking device that simply plugs directly into your
              vehicle’s OBD II port; or via an adapter for those vehicles without. No antenna or wire-splicing required.
              The installation process is quick, easy, and doesn’t require any special tools or the assistance of a
              professional installer.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <BorderBlock image={go9Image} imagePosition="right" imageWidth={400} imageBackground="#FFFFFF">
            <p>
              GO9 RUGGED: Rugged and reliable GPS tracking device Harsh conditions or external installation are no match
              for GO9 RUGGED. Geotab’s rugged telematics device is designed for fleets with heavy equipment, yellow
              iron, farm machinery, powered trailers and assets. It has the same advanced GPS technology as the Geotab
              GO, with the added benefit of being IP68 and IP69K rated (international standard for protection against
              intrusion of solids, dust, contact and water).
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </GeotabGo9Styled>
  );
}

export default GeotabGo9;
