import React from 'react';
import { NavLink } from 'react-router-dom';
import Container from '../../components/Container/Container';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import LogoImage from '../../components/LogoImage/LogoImage';
import logoCloudWise from '../../assets/images/logo/cloud-wise.jpg';
import logoGeotab from '../../assets/images/logo/geotab.jpg';
import logoGreenroad from '../../assets/images/logo/greenroad.jpg';
import logoIturan from '../../assets/images/logo/ituran.jpg';
import logoMixTelematics from '../../assets/images/logo/mix-telematics.jpg';
import { FleetTelematicsStyled, LogoWrapper } from './FleetTelematics.styled';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormContact from '../../components/Form/FormContact';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function FleetTelematics(): JSX.Element {
  return (
    <FleetTelematicsStyled>
      <SEO
        title="RGRauto | Fleet Telematics and Safety Management Systems"
      />
      <Container>
        <PageTitle>
          Fleet Telematics and Safety
          <br />
          Management Systems
        </PageTitle>
        <LogoWrapper>
          <div>
            <NavLink exact to="/cloud-wise">
              <LogoImage src={logoCloudWise} name="CloudWise" />
            </NavLink>
            <NavLink exact to="/geotab">
              <LogoImage src={logoGeotab} name="Geotab" />
            </NavLink>
            <NavLink exact to="/greenroad">
              <LogoImage src={logoGreenroad} name="Greenroad" />
            </NavLink>
          </div>
          <div>
            <NavLink exact to="/ituran">
              <LogoImage src={logoIturan} name="Ituran" />
            </NavLink>
            <NavLink exact to="/mix-telematics">
              <LogoImage src={logoMixTelematics} name="MixTelematics" />
            </NavLink>
          </div>
        </LogoWrapper>
        <SectionTitle>Contact Us</SectionTitle>
        <p>Have a question? Would you like to speak with us?</p>
        <Card>
          <CardDescription>
            Please fill out the form below and we will <br />
            contact you as soon as possible
          </CardDescription>
          <FormContact />
        </Card>
      </Container>
    </FleetTelematicsStyled>
  );
}

export default FleetTelematics;
