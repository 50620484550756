import styled from 'styled-components';
import headerBg from '../../assets/images/request-a-demo/header.jpg';

export const DemoStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
  padding-bottom: 8px;
`;

export const HeaderBackground = styled.div`
  position: absolute;
  z-index: 0;

  height: 495px;
  width: 100%;

  background-color: ${(props) => props.theme.palette.headerBackground};
  background-image: url(${headerBg});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
`;
