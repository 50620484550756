import styled from 'styled-components';

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  border-bottom: 1px solid #d9d9d9;
  padding: 16px 8px;
`;

export const ContactsIcon = styled.div`
  flex: 0 0 30px;
  width: 30px;
  margin-right: 16px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }

  ${(props) => props.theme.media.tablet} {
  }
`;

export const ContactsInfo = styled.div`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;

  padding: 4px 8px;

  div + div {
    margin-top: 16px;
  }

  a {
    color: #000000 !important;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  ${(props) => props.theme.media.phone} {
    font-size: 14px;
  }
`;

export const MapImg = styled.img`
  width: 100%;
  height: auto;
`;
