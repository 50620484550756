import styled from 'styled-components';
import solutionBg from '../../assets/images/solution/fleet-telematics.jpg';

export const SolutionsStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const SolutionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SolutionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;

  background-color: ${(props) => props.theme.palette.headerBackground};
  background-image: url(${solutionBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  max-width: 350px;
  min-height: 520px;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  flex: 0 0 100%;
  width: 100%;
  margin: 16px;

  ${(props) => props.theme.media.tablet} {
    flex: 0 0 30%;
    width: 30%;
  }

  a {
    text-decoration: none !important;
  }
`;

export const SolutionTitle = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  color: #ffffff;

  padding: 16px 8px;
  height: 70px;
`;
