import styled from 'styled-components';
import { Container } from '../Container/Container.styled';

export const HeaderStyled = styled.header<{ backgroundImage: string }>`
  height: 495px;

  background-color: ${(props) => props.theme.palette.headerBackground};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${(props) => props.theme.media.tablet} {
    background-image: url(${(props) => props.backgroundImage || 'none'});
  }

  ${(props) => props.theme.media.desktop} {
    background-size: contain;
  }

  ${Container} {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export const HeaderContent = styled.div``;

export const HeaderSubtitle = styled.div`
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.555em;

  color: #ffffff;
`;

export const HeaderTitle = styled.h1`
  font-size: 48px;
  line-height: 1.2;
  margin-top: 0;

  color: #ffffff;

  sup {
    font-size: 20px;
    top: -1.25em;
    font-weight: 400;
  }
`;

export const HeaderDescription = styled.p`
  font-size: 16px;
  color: #ffffff;
  max-width: 450px;
`;
