import React from 'react';
import { CipiaFleetSenseStyled, Function, FunctionsBlock, FunctionsWrapper } from './CipiaFleetSense.styled';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Card from '../../components/Card/Card';
import { QuoteHorizontal } from '../../components/Quote/Quote.styled';
import Section from '../../components/Section/Section';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import headerBackground from '../../assets/images/fleet-fs10/header.jpg';
import fleetDevice from '../../assets/images/fleet-fs10/fs10.png';
import icon01 from '../../assets/images/fleet-fs10/icon1.png';
import icon02 from '../../assets/images/fleet-fs10/icon2.png';
import icon03 from '../../assets/images/fleet-fs10/icon3.png';
import icon04 from '../../assets/images/fleet-fs10/icon4.png';
import icon05 from '../../assets/images/fleet-fs10/icon5.png';
import icon06 from '../../assets/images/fleet-fs10/icon6.png';
import icon07 from '../../assets/images/fleet-fs10/icon7.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function CipiaFleetSense(): JSX.Element {
  return (
    <CipiaFleetSenseStyled>
      <SEO
        title="RGRauto | Fleet sense FS10"
      />
      <Header
        title="Fleet sense FS10"
        description={
          <>
            Cipia FS10 offers an advanced in-cabin driver monitoring system designed for Fleets and Telematics Systems
            Providers (TSP)
          </>
        }
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <Card>
            <img src={fleetDevice} height={340} style={{ float: 'right', marginRight: '-72px' }} alt="Mobileye" />
            <QuoteHorizontal>
              <p>
                Using computer vision and Artificial Intelligence (AI) and with the help of an IR sensor, Cipia-FS10
                tracks the driver’s head pose, eyelids and direction of gaze to provide real-time assessment of the
                driver’s state (attentive, distracted, drowsy), actions (smoking, wearing a face mask, a seatbelt, and
                holding a phone), and identity (recognition of enrolled drivers).
              </p>
            </QuoteHorizontal>
            <p>
              The detection of driver state, actions, driver ID and camera status enable life-saving and cost-saving
              features for Fleets and telematics systems providers (TSP). The driver receives real-time alerts when
              drowsiness, distraction or dangerous actions are detected, while the Fleet Management System (FMS)
              receives tailored real-time alerts according to their needs enabling optimization and increasing efficacy
              of fleet manager / dispatcher.
            </p>
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <FunctionsBlock>
            <FunctionsWrapper>
              <Function>
                <img src={icon01} alt="FS10 Drowsiness" />
                <p>Drowsiness</p>
              </Function>
              <Function>
                <img src={icon02} alt="FS10 Holding Phone" />
                <p>Holding Phone</p>
              </Function>
              <Function>
                <img src={icon03} alt="FS10 Distraction" />
                <p>Distraction</p>
              </Function>
              <Function>
                <img src={icon04} alt="FS10 Driver ID" />
                <p>Driver ID</p>
              </Function>
              <Function>
                <img src={icon05} alt="FS10 Smoking" />
                <p>Smoking</p>
              </Function>
              <Function>
                <img src={icon06} alt="FS10 Camera Status" />
                <p>Camera Status</p>
              </Function>
              <Function>
                <img src={icon07} alt="FS10 Seatbelt" />
                <p>Seatbelt</p>
              </Function>
            </FunctionsWrapper>
          </FunctionsBlock>
        </Container>
      </Section>

      <Section backgroundColor="#F5F5F5">
        <Container>
          <VideoBlock code="aGbXTCqMfk0" />
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </CipiaFleetSenseStyled>
  );
}

export default CipiaFleetSense;
