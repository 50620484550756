/* eslint  @typescript-eslint/no-unsafe-member-access: 0 */
import React from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { ErrorMessage, FormField, FormStyled, FormControl, FormButton } from './Form.styled';
import { callRequestSchema } from './Form.schema';

const FormRequestCall = (): JSX.Element => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(callRequestSchema),
  });
  const onSubmit = (data: object) => {
    console.log(data);
    fetch(`${process.env.API_URL}/v1/request-call`, {
      method: 'POST',
      body: JSON.stringify(data) || null,
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        reset();
      })
      .catch((error) => console.log(error));
  };

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <FormField>
        <FormControl {...register('name')} placeholder="Name *" type="text" />
        <ErrorMessage>{errors.name && errors.name.message}</ErrorMessage>
      </FormField>
      <FormField>
        <FormControl
          type="tel"
          placeholder="Phone number *"
          {...register('phone', { required: false, minLength: 6, maxLength: 12 })}
        />
        <ErrorMessage>{errors.phone && errors.phone.message}</ErrorMessage>
      </FormField>
      <FormField>
        <FormControl {...register('time')} placeholder="Call Time" type="text" />
        <ErrorMessage>{errors.time && errors.time.message}</ErrorMessage>
      </FormField>

      <FormField>
        <FormButton type="submit" style={{ margin: '0 auto', textTransform: 'none' }}>
          Request a call
        </FormButton>
      </FormField>
    </FormStyled>
  );
};

export default FormRequestCall;
