import { SitemapLink } from '../pages/Sitemap/Sitemap.types';

const sitemapList: SitemapLink[] = [
  {
    title: 'Solutions',
    url: '/solutions',
    links: [
      {
        title: 'Advanced Driver Assistance Systems (ADAS)',
        url: '/mobileye',
        links: [
          {
            title: 'Mobileye',
            url: '/mobileye',
            links: [
              {
                title: 'Mobileye 8 Connect',
                url: '/mobileye/mobileye-8-connect',
              },
              // {
              //   title: 'Mobileye Shield+',
              //   url: '/mobileye/mobileye-shield',
              // },
            ],
          },
        ],
      },
      {
        title: 'Driver monitoring Systems (DMS)',
        url: '/driver-monitoring-system',
        links: [
          {
            title: 'Cipia',
            url: '/cipia',
            links: [
              {
                title: 'Fleet Sense FS10',
                url: '/cipia/fleet-sense-fs10',
              },
            ],
          },
          {
            title: 'GreenRoad',
            url: '/greenroad',
            links: [
              {
                title: 'Videosense™',
                url: '/greenroad/videosense',
              },
            ],
          },
          {
            title: 'MiX Telematics',
            url: '/mix-telematics',
            links: [
              {
                title: 'MiX Vision II',
                url: '/mix-telematics/mix-vision-ii',
              },
            ],
          },
        ],
      },
      {
        title: 'Fleet Telematics and Safety Management Systems',
        url: '/fleet-telematics',
        links: [
          {
            title: 'Cloud-Wise',
            url: '/cloud-wise',
            links: [
              {
                title: 'I-Sticker',
                url: '/cloud-wise/i-sticker',
              },
            ],
          },
          {
            title: 'Geotab',
            url: '/geotab',
            links: [
              {
                title: 'GO9',
                url: '/geotab/go9',
              },
            ],
          },
          {
            title: 'GreenRoad',
            url: '/greenroad',
            links: [
              {
                title: 'Drive™',
                url: '/greenroad/drive',
              },
            ],
          },
          // {
          //   title: 'Ituran',
          //   url: '/ituran',
          //   links: [
          //     {
          //       title: 'StarLink',
          //       url: '/ituran',
          //     },
          //   ],
          // },
          {
            title: 'MiX Telematics',
            url: '/mix-telematics',
            links: [
              {
                title: 'MyMiX Tracking™',
                url: '/mix-telematics/mix-tracking',
              },
              {
                title: 'MiX Fleet Manager™',
                url: '/mix-telematics/mix-fleet-manager',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Products',
    url: '/products',
    links: [
      {
        title: 'Cipia',
        url: '/cipia',
        links: [
          {
            title: 'Fleet Sense FS10',
            url: '/cipia/fleet-sense-fs10',
          },
        ],
      },
      {
        title: 'Cloud-Wise',
        url: '/cloud-wise',
        links: [
          {
            title: 'I-Sticker',
            url: '/cloud-wise/i-sticker',
          },
        ],
      },
      {
        title: 'Geotab',
        url: '/geotab',
        links: [
          {
            title: 'GO9',
            url: '/geotab/go9',
          },
        ],
      },
      {
        title: 'GreenRoad',
        url: '/greenroad',
        links: [
          {
            title: 'Drive™',
            url: '/greenroad/drive',
          },
        ],
      },
      // {
      //   title: 'Ituran',
      //   url: '/ituran',
      //   links: [
      //     {
      //       title: 'StarLink',
      //       url: '/ituran',
      //     },
      //   ],
      // },
      {
        title: 'MiX Telematics',
        url: '/mix-telematics',
        links: [
          {
            title: 'MyMiX Tracking™',
            url: '/mix-telematics/mix-tracking',
          },
          {
            title: 'MiX Fleet Manager™',
            url: '/mix-telematics/mix-fleet-manager',
          },
          {
            title: 'MiX Vision II',
            url: '/mix-telematics/mix-vision-ii',
          },
        ],
      },
      {
        title: 'Mobileye',
        url: '/mobileye',
        links: [
          {
            title: 'Mobileye 8 Connect',
            url: '/mobileye/mobileye-8-connect',
          },
          // {
          //   title: 'Mobileye Shield+',
          //   url: '/mobileye/mobileye-shield',
          // },
        ],
      },
    ],
  },
  {
    title: 'Demo',
    url: '/demo',
  },
  {
    title: 'About',
    url: '/about',
  },
  {
    title: 'Contact',
    url: '/contact',
  },
];

export default sitemapList;
