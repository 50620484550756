/* eslint  @typescript-eslint/no-unsafe-member-access: 0 */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Column,
  ErrorMessage,
  FlexBox,
  FormField,
  FormStyled,
  FormControl,
  FormSelect,
  FormCheckbox,
  SubmitContainer,
  FormButton,
} from './Form.styled';
import countryList from '../../data/countryList';
import fleetSizeList from '../../data/fleetSizeList';
import Captcha from '../Captcha/Captcha';
import { contactSchema } from './Form.schema';

const FormContact = (): JSX.Element => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(contactSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      jobName: '',
      email: '',
      phone: '',
      country: '_',
      fleetSize: '_',
      message: '',
      subscribe: true,
      captcha: 0,
    },
  });
  const onSubmit = (data: object) => {
    console.log(data);
    fetch(`${process.env.API_URL}/v1/contact-us`, {
      method: 'POST',
      body: JSON.stringify(data) || null,
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        reset();
      })
      .catch((error) => console.log(error));
  };

  // Captcha
  useEffect(() => {
    register('captcha');
  }, [register]);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const onVerifyCaptcha = (captchaState: boolean) => {
    setIsFormDisabled(!captchaState);
  };

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <FlexBox>
        <Column>
          <FormField>
            <FormControl {...register('firstName')} placeholder="First name *" type="text" />
            <ErrorMessage>{errors.firstName?.message}</ErrorMessage>
          </FormField>
          <FormField>
            <FormControl {...register('companyName')} placeholder="Company Name" type="text" />
            <ErrorMessage>{errors.companyName?.message}</ErrorMessage>
          </FormField>
          <FormField>
            <FormControl {...register('email')} placeholder="Email *" type="email" />
            <ErrorMessage>{errors.email?.message}</ErrorMessage>
          </FormField>
          <FormField>
            <FormSelect {...register('country', { required: true })} defaultValue="_">
              <option key="-1" value="_" disabled hidden>
                Country *
              </option>
              {countryList.map((value, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={value}>
                  {value}
                </option>
              ))}
            </FormSelect>
            <ErrorMessage>{errors.country?.message}</ErrorMessage>
          </FormField>
        </Column>
        <Column>
          <FormField>
            <FormControl {...register('lastName')} placeholder="Last name *" type="text" />
            <ErrorMessage>{errors.lastName?.message}</ErrorMessage>
          </FormField>
          <FormField>
            <FormControl {...register('jobName')} placeholder="Job Title" type="text" />
            <ErrorMessage>{errors.jobName?.message}</ErrorMessage>
          </FormField>
          <FormField>
            <FormControl
              type="tel"
              placeholder="Phone number *"
              {...register('phone', { required: false, minLength: 6, maxLength: 12 })}
            />
            <ErrorMessage>{errors.phone?.message}</ErrorMessage>
          </FormField>
          <FormField>
            <FormSelect {...register('fleetSize', { required: true })} defaultValue="_">
              <option key="-1" value="_" disabled hidden>
                Fleet Size *
              </option>
              {fleetSizeList.map((value, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={value}>
                  {value}
                </option>
              ))}
            </FormSelect>
            <ErrorMessage>{errors.fleetSize?.message}</ErrorMessage>
          </FormField>
        </Column>
      </FlexBox>

      <FormField style={{ padding: '0 8px' }}>
        <FormControl as="textarea" {...register('message', { required: true })} placeholder="Your Message" rows={5} />
      </FormField>

      <FormField style={{ padding: '0 8px', textAlign: 'center' }}>
        <FormCheckbox>
          <input type="checkbox" {...register('subscribe', { required: true })} id="checkbox-subscribe" />
          <label htmlFor="checkbox-subscribe">
            Yes, I would like to receive marketing communications regarding RGRAuto.com products, services, and events
          </label>
        </FormCheckbox>
        <ErrorMessage>{errors.subscribe?.message}</ErrorMessage>
      </FormField>

      <SubmitContainer>
        <Captcha onVerify={onVerifyCaptcha} />
        <FormButton type="submit" disabled={isFormDisabled}>
          Submit
        </FormButton>
      </SubmitContainer>
    </FormStyled>
  );
};

export default FormContact;
