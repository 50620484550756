import styled from 'styled-components';

const framePadding = 24;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 540px;
  padding: 0 ${framePadding}px;
  //margin: 24px 0;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  iframe {
    //position: absolute;
    //top: 0;
    //left: 0;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const VideoFrame = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - ${framePadding * 2}px);
  margin: ${framePadding}px -${framePadding}px;

  border: 2px solid ${(props) => props.theme.palette.greenColor};
`;
