import styled from 'styled-components';
import backgroundFlag from '../../assets/images/mix-fleet-manager/flag.png';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';

export const MixFleetManagerStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const AppTruckWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  > div {
    flex: 0 1 100%;
    width: 100%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  ${(props) => props.theme.media.tablet} {
    > div:first-child {
      flex: 0 1 55%;
      width: 55%;
    }
    > div:last-child {
      flex: 0 1 45%;
      width: 45%;
    }
    > div:last-child {
      padding-bottom: 24px;
      border-bottom: 4px solid ${(props) => props.theme.palette.greenColor};
    }
  }

  ${(props) => props.theme.media.phone} {
    > div:last-child {
      display: none;
    }
  }
`;

export const CompliantBlock = styled.div`
  background-color: #f5f5f5;
  border: 2px solid #00a651;
  padding: 36px 64px;

  background-image: url(${backgroundFlag});
  background-repeat: no-repeat;
  //background-size: contain;
  //background-position: -50px bottom;
  background-size: 221px 203px;
  background-position: -30px 22px;

  ${SectionTitle} {
    //margin-left: 120px;
    margin-left: 100px;
    padding-top: 0;
  }

  p {
    padding-bottom: 0;
  }

  ${(props) => props.theme.media.phone} {
    background-image: none;
    padding: 36px;

    ${SectionTitle} {
      margin-left: 0;
    }
  }
`;
