import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import {
  ModalFeatureBlock,
  ModalFeaturesList,
  ModalLead,
  ModalList,
  ModalMainImage,
  ModalMainWrapper,
} from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/road-facing-camera.png';
import { enableBodyScroll } from '../../lib/scrollLock';
import icon01 from '../../assets/images/mix-vision/icon_1-1.png';
import icon02 from '../../assets/images/mix-vision/icon_1-2.png';
import icon03 from '../../assets/images/mix-vision/icon_1-3.png';

const MixVisionModal01 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Road-Facing Camera"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>
        The road-facing camera detects AI events whilst on the road including collision warning, lane departure and
        following distance.
      </ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="Road-Facing Camera" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Windshield-mounted</li>
            <li>Detects AI events on the road</li>
            <li>HD camera detects ADAS (Advanced Driver Assistance System) events</li>
            <li>Full color image</li>
            <li>1080p @ 20 frames a second</li>
            <li>Built-in Cat4 LTE modem with fallback to GSM/GPRS/EDGE</li>
            <li>Built-in microphone for audio recording</li>
            <li>
              Built-in speaker for audio (beep) and voice alerts in
              <br />
              English Beep and Voice alerts can be disabled and replaced with visual alerts on the AI Driving Coach
            </li>
          </ModalList>
        </div>
      </ModalMainWrapper>

      <ModalFeaturesList>
        <ModalFeatureBlock>
          <div>
            <img src={icon01} height="50px" alt="Lane Departure" />
          </div>
          <div>
            <p>
              <strong>Lane Departure</strong>
            </p>
            <p>
              Monitor dangerous lane departures where the front wheel is about to cross the line without an indicator
              being on (the indicator needs to be on for at least 3 seconds prior)
            </p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon02} height="50px" alt="Collision Warning" />
          </div>
          <div>
            <p>
              <strong>Collision Warning</strong>
            </p>
            <p>
              Monitor forward collisions warnings with ADAS (the video upload is triggered immediately and available on
              MiX Fleet Manager)
            </p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon03} height="50px" alt="Following Distance" />
          </div>
          <div>
            <p>
              <strong>Following Distance</strong>
            </p>
            <p>
              Following distance is estimated in seconds and an event is triggered, alerting the driver and fleet owner
              in real-time
            </p>
          </div>
        </ModalFeatureBlock>
      </ModalFeaturesList>
    </Modal>
  );
});

export default MixVisionModal01;
