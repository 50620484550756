import React from 'react';
import { Description, Link, LinkWrapper, Title, Wrapper } from './SliderTextBlock.styled';
import { SliderTextBlockProps } from './SliderTextBlock.types';

const SliderTextBlock = ({ title, description, links }: SliderTextBlockProps): JSX.Element => (
  <Wrapper>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <LinkWrapper>
      {links.map((link, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Link key={i} href={link.url} title={link.title} variant={link.variant}>
          {link.title}
        </Link>
      ))}
    </LinkWrapper>
  </Wrapper>
);

export default SliderTextBlock;
