import React from 'react';
import {
  IndustryCardText,
  IndustryCardImage,
  IndustryCardLink,
  IndustryCardLinks,
  IndustryCardTitle,
  IndustryCardWrapper,
  IndustryCardImageWrapper,
  IndustryCardDescriptionWrapper,
} from './IndustryCard.styled';
import { IndustryCardProps } from './IndustryCard.types';

const IndustryCard = ({ title, description, image, links }: IndustryCardProps): JSX.Element => (
  <IndustryCardWrapper>
    <IndustryCardImageWrapper>
      <IndustryCardImage image={image} />
    </IndustryCardImageWrapper>
    <IndustryCardDescriptionWrapper>
      <IndustryCardTitle>{title}</IndustryCardTitle>
      <IndustryCardText>{description}</IndustryCardText>
      <IndustryCardLinks>
        {links &&
          links.map((link, i) => (
            <IndustryCardLink
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              href={link.url}
              target={link.target ?? '_self'}
              title={link.description ?? link.title}
            >
              {link.title}
            </IndustryCardLink>
          ))}
      </IndustryCardLinks>
    </IndustryCardDescriptionWrapper>
  </IndustryCardWrapper>
);
IndustryCard.defaultProps = {
  description: null,
  imageAtLeft: true,
  links: null,
};

export default IndustryCard;
