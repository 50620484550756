import React, { useState } from 'react';
import { VideoFrame, VideoWrapper } from './VideoBlock.styled';

type VideoBlockType = {
  code: string;
  preview?: string;
};

function VideoBlock({ code, preview }: VideoBlockType): JSX.Element {
  const url = `https://www.youtube.com/embed/${code}`;
  const hasPreview = !!preview;
  const [previewVisible, setPreviewVisible] = useState(true);

  if (hasPreview) {
    return (
      <VideoWrapper>
        <VideoFrame />
        <div
          role="button"
          style={{ display: previewVisible ? 'block' : 'none', height: '100%' }}
          onClick={() => setPreviewVisible(false)}
        >
          <img src={preview} style={{ cursor: 'pointer' }} alt={code} />
        </div>
        <div style={{ display: !previewVisible ? 'block' : 'none', height: '100%' }}>
          <iframe src={url} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="video" />
        </div>
      </VideoWrapper>
    );
  }

  return (
    <VideoWrapper>
      <VideoFrame />
      <iframe src={url} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="video" />
    </VideoWrapper>
  );
}

VideoBlock.defaultProps = {
  preview: '',
};

export default VideoBlock;
