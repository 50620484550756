import country from 'country-list-js';

const predefinedCountries = ['United States', 'Canada', 'Mexico'];

const countryList: string[] = [];

predefinedCountries.forEach((countryName) => {
  countryList.push(countryName);
});

country
  .names()
  .sort((a, b) => a.localeCompare(b))
  .forEach((countryName) => {
    if (predefinedCountries.includes(countryName)) {
      return;
    }
    countryList.push(countryName);
  });

export default countryList;
