import React from 'react';
import { BorderBlockWrapper, BorderBlockImage, BorderBlockText } from './BorderBlock.styled';
import { BorderBlockProps } from './BorderBlock.types';

const BorderBlock = ({
  image,
  imagePosition,
  imageWidth,
  imageOffset,
  imageBackground,
  children,
  style,
}: BorderBlockProps): JSX.Element => (
  <BorderBlockWrapper imagePosition={imagePosition} imageOffset={imageOffset} style={style}>
    <BorderBlockImage image={image} width={imageWidth} background={imageBackground} offset={imageOffset}>
      <div />
    </BorderBlockImage>
    <BorderBlockText>{children}</BorderBlockText>
  </BorderBlockWrapper>
);

export default BorderBlock;
