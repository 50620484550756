import styled from 'styled-components';
import backgroundImage from '../../assets/images/background/industry-card-bg.png';

export const IndustryCardImageWrapper = styled.div`
  min-height: 200px;
  height: 200px;
  width: 100%;
`;

export const IndustryCardDescriptionWrapper = styled.div`
  padding: 24px;

  ${(props) => props.theme.media.tablet} {
    padding: 32px;
  }
`;

export const IndustryCardWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 45px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;

  width: 100%;
  flex: 0 0 100%;
  margin: 16px 0;

  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${backgroundImage});
  background-position: right bottom;

  ${(props) => props.theme.media.tablet} {
    width: 320px;
    flex: 0 0 320px;
  }
`;

export const IndustryCardImage = styled.div<{ image: string }>`
  height: 100%;
  width: 100%;

  background-color: transparent;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const IndustryCardTitle = styled.div`
  position: relative;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 700;
  color: ${(props) => props.theme.palette.blueColor};
  margin: 0 0 36px;

  &::after {
    position: absolute;
    content: '';
    bottom: -12px;
    left: 0;
    background-color: ${(props) => props.theme.palette.blueColor};
    height: 2px;
    width: 64px;
  }
`;

export const IndustryCardText = styled.div`
  font-size: 24px;
  line-height: 1.2;
  color: ${(props) => props.theme.palette.blueColor};
`;

export const IndustryCardLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.greenColor};
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
`;

export const IndustryCardLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 48px;

  a {
    margin: 8px;
    cursor: pointer;
  }
`;
