import React, { useState } from 'react';
import { FeatureCardProps } from './FeatureCard.types';
import { Description, FeatureCardWrapper, Icon, Title } from './FeatureCard.styled';

const FeatureCard = ({ icons, title, description }: FeatureCardProps): JSX.Element => {
  const [hovered, setHovered] = useState(false);

  return (
    <FeatureCardWrapper onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Icon src={hovered ? icons.hover : icons.main} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </FeatureCardWrapper>
  );
};

export default FeatureCard;
