import React from 'react';
import { CustomersWrapper } from './CustomersBlock.styled';
import LogoImage from '../LogoImage/LogoImage';
import ald from '../../assets/images/customers/ald.png';
import arval from '../../assets/images/customers/arval.png';
import astrazeneca from '../../assets/images/customers/astrazeneca.png';
import boehringerIngelheim from '../../assets/images/customers/boehringer-ingelheim.png';
import cocaCola from '../../assets/images/customers/coca-cola.png';
import danone from '../../assets/images/customers/danone.png';
import leaseplan from '../../assets/images/customers/leaseplan.png';
import linde from '../../assets/images/customers/linde.png';
import maxxium from '../../assets/images/customers/maxxium.png';
import nabors from '../../assets/images/customers/nabors.png';
import orange from '../../assets/images/customers/orange.png';
import orica from '../../assets/images/customers/orica.png';
import philipMorris from '../../assets/images/customers/philip-morris.png';
import praxair from '../../assets/images/customers/praxair.png';
import schlumberger from '../../assets/images/customers/schlumberger.png';
import shell from '../../assets/images/customers/shell.png';

const CustomersBlock = (): JSX.Element => (
  <CustomersWrapper>
    <LogoImage src={ald} name="ald" width="250px" />
    <LogoImage src={arval} name="arval" width="250px" />
    <LogoImage src={astrazeneca} name="astrazeneca" width="250px" />
    <LogoImage src={boehringerIngelheim} name="boehringerIngelheim" width="250px" />
    <LogoImage src={cocaCola} name="cocaCola" width="250px" />
    <LogoImage src={danone} name="danone" width="250px" />
    <LogoImage src={leaseplan} name="leaseplan" width="250px" />
    <LogoImage src={linde} name="linde" width="250px" />
    <LogoImage src={maxxium} name="maxxium" width="250px" />
    <LogoImage src={nabors} name="nabors" width="250px" />
    <LogoImage src={orange} name="orange" width="250px" />
    <LogoImage src={orica} name="orica" width="250px" />
    <LogoImage src={philipMorris} name="philipMorris" width="250px" />
    <LogoImage src={praxair} name="praxair" width="250px" />
    <LogoImage src={schlumberger} name="schlumberger" width="250px" />
    <LogoImage src={shell} name="shell" width="250px" />
  </CustomersWrapper>
);

export default CustomersBlock;
