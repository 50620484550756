import styled from 'styled-components';
import { BorderBlockWrapper } from '../../components/BorderBlock/BorderBlock.styled';
import { SectionStyled } from '../../components/Section/Section.styled';
import backgroundMainSection from '../../assets/images/background/isticker-background.png';

export const CloudWiseIStickerStyled = styled.div``;

export const MainSection = styled(SectionStyled)`
  ${BorderBlockWrapper} {
    p {
      padding: 0 16px;
    }
  }

  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${backgroundMainSection});
  background-position: center bottom -200px;
`;

export const AboutBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 18px;

  div {
    flex: 0 1 auto;
    margin: 16px;
  }

  > div:first-child {
    position: relative;
    margin-top: 32px;

    &::before {
      position: absolute;
      content: '';
      top: -24px;
      left: 0;
      background: ${(props) => props.theme.palette.greenColor};
      height: 4px;
      width: 64px;
    }
  }

  ${(props) => props.theme.media.phone} {
    flex-direction: column;

    > div:first-child {
      order: 1;
    }
    > div:last-child {
      order: 0;
    }
  }
`;
export const AboutBlockImage = styled.div`
  img {
    width: auto;
    height: 314px;
  }

  ${(props) => props.theme.media.phone} {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin: 8px;
  }

  ${(props) => props.theme.media.phone} {
    justify-content: center;
  }
`;

export const EdgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    flex: 0 1 45%;
    margin: 16px;
  }

  ${(props) => props.theme.media.phone} {
    justify-content: center;

    > div {
      flex: 0 1 100%;
      margin: 16px;
    }
  }
`;
