import styled from 'styled-components';

export const CustomersWrapper = styled.div`
  background: #ffffff;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  > div {
    flex: 0 0 50%;
  }
  
  ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
    > div {
      flex: 0 0 25%;
    }
  }
`;
