import styled from 'styled-components';
import { Column } from '../../components/Form/Form.styled';

export const ContactStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
  padding-bottom: 8px;

  ${(props) => props.theme.media.phone} {
    ${Column}:first-child {
      order: 1;
    }
    ${Column}:last-child {
      order: 0;
    }
  }
`;
