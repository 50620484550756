import React from 'react';
import { BlueBoxContent, BlueBoxImage, BlueBoxSection, FeaturesWrapper, MixTrackingStyled } from './MixTracking.styled';
import headerBackground from '../../assets/images/mymix-tracking/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import imagePhone from '../../assets/images/mymix-tracking/about.png';
import imageFeatures01 from '../../assets/images/mymix-tracking/features1.png';
import imageFeatures02 from '../../assets/images/mymix-tracking/features2.png';
import imageFeatures03 from '../../assets/images/mymix-tracking/features3.png';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixTracking(): JSX.Element {
  return (
    <MixTrackingStyled>
      <SEO
        title="RGRauto | MyMiX Tracking"
      />
      <Header
        title="MyMiX Tracking"
        description="Ground-breaking app-based fleet management.
Track & monitor driver safety behavior with no hardware."
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <SectionDescription>
            <p>
              With the MyMiX Tracking™ app, you can maintain visibility of non-permanent fleet vehicles and contractors
              and monitor risky driver behavior – no hardware installation needed!
            </p>
            <hr />
          </SectionDescription>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>WHAT IS MyMiX TRACKING?</SectionTitle>
          <p>
            MyMiX Tracking leverages cellphone technology to enable real-time tracking of drivers while recording,
            measuring, and enabling real-time self-correction of risky driving behaviour events including speeding,
            harsh braking, harsh acceleration, and mobile phone use while driving.
          </p>
        </Container>
      </Section>

      <BlueBoxSection>
        <Container>
          <BlueBoxContent>
            <p>
              The app-based solution is an extension of MyMiX, a comprehensive driver engagement app that also equips
              drivers with valuable information and alerts to improve their driving style, including immediate, in-cab
              audible feedback. Our winning formula allows you to get started with minimal effort, tangible ROI, and no
              interruptions to your daily operations.
            </p>
          </BlueBoxContent>
          <BlueBoxImage>
            <img src={imagePhone} alt="phone" height="470px" />
          </BlueBoxImage>
        </Container>
      </BlueBoxSection>

      <Section>
        <Container>
          <SectionDescription>
            <p>
              Our winning formula allows you to get started with minimal effort, tangible ROI, and no interruptions to
              your daily operations.
            </p>
          </SectionDescription>
          <FeaturesWrapper>
            <div>
              <img src={imageFeatures01} alt="MyMiX driver app" />
              <h3>MyMiX driver app:</h3>
              <div>Performance Reporting & Task Management</div>
            </div>
            <div>
              <h2>NEW FEATURES</h2>
              <img src={imageFeatures02} alt="MyMiX Tracking" />
              <h3>MyMiX Tracking:</h3>
              <div>Device Tracking, driving event detection & real-time driver feedback.</div>
            </div>
            <div>
              <img src={imageFeatures03} alt="Fleet Management" />
              <h3>
                Mobile Application-based Fleet <br />
                Management
              </h3>
            </div>
          </FeaturesWrapper>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MixTrackingStyled>
  );
}

export default MixTracking;
