import React from 'react';
import { NavLink } from 'react-router-dom';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Container from '../../components/Container/Container';
import { DriverMonitoringSystemStyled, SystemsWrapper } from './DriverMonitoringSystem.styled';
import cipiaImage from '../../assets/images/dms/cipia.jpg';
import mixtelematicsImage from '../../assets/images/dms/mixtelematics.jpg';
import greenroadImage from '../../assets/images/dms/greenroad.jpg';
import LogoImage from '../../components/LogoImage/LogoImage';
import cipiaLogo from '../../assets/images/logo/cipia.svg';
import mixtelematicsLogo from '../../assets/images/logo/mixtelematics.svg';
import greenroadLogo from '../../assets/images/logo/greenroad.svg';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormContact from '../../components/Form/FormContact';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function DriverMonitoringSystem(): JSX.Element {
  return (
    <DriverMonitoringSystemStyled>
      <SEO
        title="RGRauto | Driver Monitoring Systems (DMS)"
      />
      <Container>
        <PageTitle>Driver Monitoring Systems (DMS)</PageTitle>
        <SystemsWrapper>
          <div>
            <NavLink exact to="/cipia">
              <img src={cipiaImage} height="400" alt="cipia" />
              <hr />
              <LogoImage src={cipiaLogo} name="cipia" height="80px" />
            </NavLink>
          </div>
          <div>
            <NavLink exact to="/mix-telematics">
              <img src={mixtelematicsImage} height="400" alt="mix-telematics" />
              <hr />
              <LogoImage src={mixtelematicsLogo} name="mixtelematicsLogo" height="80px" />
            </NavLink>
          </div>
          <div>
            <NavLink exact to="/greenroad">
              <img src={greenroadImage} height="400" alt="greenroad" />
              <hr />
              <LogoImage src={greenroadLogo} name="greenroadLogo" height="80px" />
            </NavLink>
          </div>
        </SystemsWrapper>
        <SectionTitle>Contact Us</SectionTitle>
        <p>Have a question? Would you like to speak with us?</p>
        <Card>
          <CardDescription>
            Please fill out the form below and we will <br />
            contact you as soon as possible
          </CardDescription>
          <FormContact />
        </Card>
      </Container>
    </DriverMonitoringSystemStyled>
  );
}

export default DriverMonitoringSystem;
