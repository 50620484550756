import React from 'react';
import {
  AboutBlockImage,
  AboutBlockWrapper,
  CloudWiseIStickerStyled,
  EdgeWrapper,
  FeaturesWrapper,
  MainSection,
} from './CloudWiseISticker.styled';
import headerBackground from '../../assets/images/i-sticker/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import FlipBlock from '../../components/FlipBlock/FlipBlock';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import imageDescriptionMain from '../../assets/images/i-sticker/indrive-img.jpg';
import imageSticker from '../../assets/images/i-sticker/i-sticker.png';
import imageAbout from '../../assets/images/i-sticker/about.png';
import imageFeature01 from '../../assets/images/i-sticker/features-square-1.svg';
import imageFeature02 from '../../assets/images/i-sticker/features-square-2.svg';
import imageFeature03 from '../../assets/images/i-sticker/features-square-3.svg';
import imageFeature04 from '../../assets/images/i-sticker/features-square-4.svg';
import imageFeature05 from '../../assets/images/i-sticker/features-square-5.svg';
import imageFeature06 from '../../assets/images/i-sticker/features-square-6.svg';
import imageFeature07 from '../../assets/images/i-sticker/features-square-7.svg';
import imageFeature08 from '../../assets/images/i-sticker/features-square-8.svg';
import imageFeature09 from '../../assets/images/i-sticker/features-square-9.svg';
import imageFeature10 from '../../assets/images/i-sticker/features-square-10.svg';
import imageEdge01 from '../../assets/images/i-sticker/edge-icon-1.svg';
import imageEdge02 from '../../assets/images/i-sticker/edge-icon-2.svg';
import imageEdge03 from '../../assets/images/i-sticker/edge-icon-3.svg';
import imageEdge04 from '../../assets/images/i-sticker/edge-icon-4.svg';
import imageEdge05 from '../../assets/images/i-sticker/edge-icon-5.svg';
import imageEdge06 from '../../assets/images/i-sticker/edge-icon-6.svg';
import imageEdge07 from '../../assets/images/i-sticker/edge-icon-7.svg';
import imageEdge08 from '../../assets/images/i-sticker/edge-icon-8.svg';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import EdgeBlock from '../../components/EdgeBlock/EdgeBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function CloudWiseISticker(): JSX.Element {
  return (
    <CloudWiseIStickerStyled>
      <SEO
        title="RGRauto | I-Sticker"
      />
      <Header
        title="I-Sticker"
        description="The I-Sticker by Cloud-Wise is the New Generation Telematics that is super easy for implementation and super easy for use."
        backgroundImage={headerBackground}
      />
      <MainSection>
        <Container>
          <BorderBlock image={imageDescriptionMain} imagePosition="left" imageWidth={544}>
            <p style={{ textAlign: 'center' }}>
              <img src={imageSticker} alt="I-Sticker" style={{ width: '100%' }} />
            </p>
            <p>
              I-Sticker is the New Generation of Telematics that requires no installation, is easy to implement and
              super user-friendly.
            </p>
            <p>
              It is truly installation-free, so unlike other telematics solutions, has zero overhead and maintenance
              costs. It is a real game changer in the telematics industry.
            </p>
            <p>
              Fleet Managers know how important telematics is, but they are also aware of the inconvenience caused by
              the need to install these devices and service them during the lifetime of the vehicle.
            </p>
          </BorderBlock>
        </Container>
        <Container>
          <AboutBlockWrapper>
            <div>
              <p>
                Every company, no matter the size, that operates a fleet of vehicles and has had experience working with
                telematics is looking for a solution that will free them from hardwired or OBDll devices requiring
                installation. Implementation and servicing these devices have become the Achilles Heel of current
                telematics solutions.
              </p>
              <p>
                With over 20 years of experience working with large and small fleets, we listened to our customers, and
                together with the R&D team at Cloud-wise, present the I-Sticker.
              </p>
              <p>
                Just Stick and Go.
              </p>
            </div>
            <AboutBlockImage>
              <img src={imageAbout} width="465px" alt="About I-Sticker" />
            </AboutBlockImage>
          </AboutBlockWrapper>
        </Container>
      </MainSection>

      <Section backgroundColor="#1C2F75">
        <Container>
          <SectionTitle variant="inverted">Key Features</SectionTitle>
          <FeaturesWrapper>
            <FlipBlock
              icon={imageFeature01}
              title="Installation Free"
              description="Just press I-STICKER onto your windshield and it’s ready to go"
            />
            <FlipBlock
              icon={imageFeature02}
              title="Automatic driver identification"
              description="Driver ID is automatically assigned to every trip without additional hardware"
            />
            <FlipBlock
              icon={imageFeature03}
              title="DRIVER BEHAVIOR ANALYSIS AND SCORING"
              description="Driver scores are presented for each trip or given period
                  of time"
            />
            <FlipBlock
              icon={imageFeature04}
              title="ACCIDENT DETECTION AND RECONSTRUCTION"
              description="Embedded sensors provide crash forensics"
            />
            <FlipBlock
              icon={imageFeature05}
              title="UBI/BBI COMPLIANT"
              description="Perfect for 'Smart Insurance Programs'"
            />

            <FlipBlock
              icon={imageFeature06}
              title="DRIVING EVENTS STORED FOR UP TO ONE YEAR"
              description="Internal memory stores all driving logs even when
                  offline"
            />
            <FlipBlock
              icon={imageFeature07}
              title="TAMPER NOTIFICATION"
              description="A notification is automatically generated if the device is
                  tampered with"
            />
            <FlipBlock
              icon={imageFeature08}
              title="EASY-TO-USE WEB PORTAL AND MOBILE APP"
              description="Intuitive and user-friendly mobile interface and web
                  portal"
            />
            <FlipBlock
              icon={imageFeature09}
              title="AUTOMATED REPORTS AND ALERTS"
              description="Reports and alerts can be received via email or text
                  messages"
            />
            <FlipBlock
              icon={imageFeature10}
              title="FUEL CONSUMPTION AND EMISSIONS DATA"
              description={
                <>
                  Provides the information you need to reduce fuel cost and CO<sub>2</sub> emissions
                </>
              }
            />
          </FeaturesWrapper>
        </Container>
      </Section>

      <Section backgroundColor="#F5F5F5">
        <Container>
          <VideoBlock code="K-bji7YGL8g" />
        </Container>
      </Section>

      <Section backgroundColor="#1C2F75">
        <Container>
          <SectionTitle variant="inverted">Edge</SectionTitle>
          <EdgeWrapper>
            <EdgeBlock
              icon={imageEdge01}
              title="Installation-free"
              description="Save installation costs and down time"
            />
            <EdgeBlock
              icon={imageEdge02}
              title="Totally wireless and autonomous"
              description="Built-in sensors collect all the data you really need"
            />
            <EdgeBlock
              icon={imageEdge03}
              title="Ready-to-go in one minute"
              description="Peel-and-stick, hassle-free deployment"
            />
            <EdgeBlock icon={imageEdge04} title="Powered by the Sun" description="No need to replace batteries" />
            <EdgeBlock
              icon={imageEdge05}
              title="SIM-free"
              description="Save on cellular fees and eliminate SIM complications"
            />
            <EdgeBlock
              icon={imageEdge06}
              title="Protects your employees' privacy"
              description="Allows flexibility when to transmit data and avoid the 'Big Brother' image"
            />
            <EdgeBlock
              icon={imageEdge07}
              title="No need for un-installing"
              description="Just change the 3M® adhesive pads and apply to a different vehicle"
            />
            <EdgeBlock
              icon={imageEdge08}
              title="Protects the vehicle warranty"
              description="Does not connect to the vehicle so no battery drain, vehicle hacking, error messages"
            />
          </EdgeWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Request a demo</SectionTitle>
            <CardText>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </CloudWiseIStickerStyled>
  );
}

export default CloudWiseISticker;
