import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Container from '../../components/Container/Container';
import { DemoStyled, HeaderBackground } from './Demo.styled';
import Section from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import CustomersBlock from '../../components/CustomersBlock/CustomersBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Demo(): JSX.Element {
  return (
    <DemoStyled>
      <SEO
        title="RGRauto | Demo"
      />
      <HeaderBackground />
      <Section backgroundColor="transparent" style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle variant="inverted">Request a Demo</PageTitle>
          <p style={{ color: '#FFFFFF', marginBottom: '36px' }}>We are glad you are interested in our Solutions!</p>
          <Card>
            <CardDescription>
              To request a demo, please complete the form below <br />
              and we will contact you as soon as possible
            </CardDescription>
            <FormRequest />
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>We proudly serve private small-to-medium fleets as well as large corporate fleets</SectionTitle>
          <CustomersBlock />
        </Container>
      </Section>
    </DemoStyled>
  );
}

export default Demo;
