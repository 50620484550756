import styled from 'styled-components';

export const CaptchaStyled = styled.div`
  font-size: 18px;
  line-height: 1;

  label {
    display: inline-block;

    color: ${(props) => props.theme.palette.placeholderColor};
  }

  input {
    display: inline-block;

    width: 26px;
    text-align: center;

    background: #ffffff;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.palette.placeholderColor};
    outline: none;
    appearance: none;

    margin-left: 8px;
  }
`;
