import styled from 'styled-components';

export const FlipBlockFront = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlipBlockBack = styled.div`
  display: flex;
  justify-content: center;
  transform: rotateY(180deg);
`;

export const FlipBlockWrapper = styled.div`
  height: 200px;
  width: 200px;
  transition: all 0.1s linear;
  perspective: 1000px;
  border-radius: 15px;
  position: relative;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 15px;
    background-color: #fff;
    align-items: center;
    backface-visibility: hidden;
    transition: 1s;
    transform-style: preserve-3d;
  }

  :hover ${FlipBlockFront} {
    transform: rotateY(180deg);
  }

  :hover ${FlipBlockBack} {
    transform: rotateY(360deg);
  }
`;

export const FlipBlockIcon = styled.img`
  height: 80px;
  object-fit: cover;
  margin-top: -24px; // из-за FlipBlockArrow
`;
export const FlipBlockTitle = styled.div`
  color: ${(props) => props.theme.palette.textColor};
  font-weight: 700;
  font-size: 12px;
  display: block;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: 8px;
`;
export const FlipBlockDescription = styled.div`
  border-radius: 15px;
  border: 2px solid ${(props) => props.theme.palette.textColor};
  width: 86%;
  height: 86%;
  margin: 8px;

  color: ${(props) => props.theme.palette.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
  z-index: -1;

  font-size: 13px;
`;
export const FlipBlockArrow = styled.img`
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  background-color: #fff;
`;
