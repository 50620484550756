import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import getStartedLeft from '../../assets/images/home/get-started-background-left.png';
import getStartedRight from '../../assets/images/home/get-started-background-right.png';

export const HomeStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;
`;

export const AutoplaySliderWrapper = styled.div`
  position: relative;
  height: 595px;
`;

export const GetStartedBlock = styled.div`
  min-height: 165px;

  background-color: rgba(196, 196, 196, 0.12);
  border-radius: 5px;

  background-image: url(${getStartedLeft}), url(${getStartedRight});
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right center;
  background-size: auto 50%, auto 50%;

  padding: 32px;
`;
export const GetStartedBlockFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    margin: 8px 24px;
  }

  div {
    color: ${(props) => props.theme.palette.darkBlueColor};
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
export const GetStartedBlockButtonWrapper = styled.div`
  margin-top: 16px;
  text-align: center;
`;

export const AboutTitle = styled.h2`
  font-size: 36px;
  line-height: 1.1;
  font-weight: 700;
  color: #ffffff;

  position: relative;
  margin: 0 0 32px;

  &::after {
    position: absolute;
    content: '';
    bottom: -24px;
    left: 0;
    background-color: #ffffff;
    height: 2px;
    width: 124px;
  }
`;
export const AboutDescription = styled.div`
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffff;

  padding: 24px 0;
  text-align: justify;
`;

export const AboutTextWrapper = styled.div`
  width: 100%;
  flex: 0 0 100%;
  order: 1;
  margin-top: 24px;
  padding: 32px;
`;
export const AboutImageWrapper = styled.div`
  width: 100%;
  flex: 0 0 100%;
  order: 0;
  display: none;

  ${(props) => props.theme.media.tablet} {
    display: block;
    width: 50%;
    flex: 0 0 50%;
    order: 1;
    margin-left: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
export const AboutWrapper = styled.section`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${(props) => props.theme.media.tablet} {
    flex-direction: row;
  }
`;

export const BottomSectionTitle = styled.h2`
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  display: block;
  max-width: 600px;
  margin: 32px auto;
`;

export const DefaultButton = styled(NavLink)`
  display: inline-block;
  min-width: 200px;

  font-size: 18px;
  line-height: 1;
  font-weight: 700;

  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 16px 24px;

  text-align: center;

  transition: all 0.15s ease;
  color: #ffffff;
  background-color: transparent;

  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.palette.blueColor};
    background-color: #ffffff;
  }
`;
export const OutlinedButton = styled(DefaultButton)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.darkBlueColor};
  background-color: #ffffff;
  border-color: ${(props) => props.theme.palette.darkBlueColor};

  &:hover {
    color: #ffffff;
    background-color: ${(props) => props.theme.palette.darkBlueColor};
  }
`;
export const OutlinedWhiteButton = styled(DefaultButton)`
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;

  &:hover {
    color: ${(props) => props.theme.palette.darkBlueColor};
    background-color: #ffffff;
  }
`;
export const WhiteButton = styled(DefaultButton)`
  color: ${(props) => props.theme.palette.darkBlueColor};
  background-color: #ffffff;
  border-color: #ffffff;

  &:hover {
    color: #ffffff;
    background-color: ${(props) => props.theme.palette.darkBlueColor};
    border-color: ${(props) => props.theme.palette.darkBlueColor};
  }
`;
export const BlueButton = styled(DefaultButton)`
  color: #ffffff;
  background-color: ${(props) => props.theme.palette.darkBlueColor};
  border-color: ${(props) => props.theme.palette.darkBlueColor};

  &:hover {
    color: ${(props) => props.theme.palette.darkBlueColor};
    background-color: #ffffff;
    border-color: ${(props) => props.theme.palette.darkBlueColor};
  }
`;

export const FlagBlockFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    margin: 8px 24px;
  }

  div {
    color: white;
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  }
`;