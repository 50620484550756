import React from 'react';
import { LogoImageStyled } from './LogoImage.styled';

type LogoImageProps = {
  src: string;
  name: string;
  height?: string | null;
  width?: string | null;
};
const LogoImage = ({ src, name, height, width }: LogoImageProps): JSX.Element => (
  <LogoImageStyled height={height} width={width}>
    <img src={src} alt={name} title={name} />
  </LogoImageStyled>
);

LogoImage.defaultProps = {
  height: null,
  width: null,
};

// const LogoImage = ({ src, name }: { src: string; name: string }): JSX.Element => {
//   return <LogoImageStyled src={src} alt={name} title={name} />;
// };

export default LogoImage;
