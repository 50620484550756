import styled from 'styled-components';
import { Container } from '../../components/Container/Container.styled';
import backgroundCenter from '../../assets/images/background/cipia-center.png';

export const GeotabStyled = styled.div`
  position: relative;
  margin: 0;
  background-color: #ffffff;

  padding-bottom: 8px;

  ${Container} {
    background-image: url(${backgroundCenter});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
  }
`;
