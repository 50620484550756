import styled from 'styled-components';

const deviceColor = '#00aec0';

export const DeviceBlockWrapper = styled.div`
  position: relative;
  margin: 0;
  //background-color: #FFFFFF;

  padding-bottom: 8px;

  display: flex;

  ${(props) => props.theme.media.tablet} {
  }
`;

export const DeviceBlockImage = styled.div<{ borderType: number }>`
  display: inline-block;
  align-self: flex-start;

  img {
    margin: 0 auto;
    display: block;
    max-width: 100px;
    height: auto;

    padding: 4px;
    border: 2px solid ${deviceColor};
    border-radius: ${(props) => (props.borderType ? '50%' : '15%')};
    background-color: #ffffff;
  }

  margin-right: 16px;
`;

export const DeviceBlockNumber = styled.div`
  background: ${deviceColor};
  color: #ffffff;

  display: inline-block;

  font-size: 14px;
  font-weight: 500;

  line-height: 18px;
  width: 18px;
  height: 18px;
  text-align: center;

  border: 0;
  border-radius: 50%;

  margin-left: 8px;
`;

export const DeviceBlockDescription = styled.div`
  margin-top: 8px;
  padding: 8px;
  border-radius: 5px;

  p {
    margin: 0;
  }
`;
