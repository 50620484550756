import React from 'react';
import { SectionStyled } from './Section.styled';
import { SectionProps } from './Section.types';

const Section = ({ backgroundColor, backgroundImage = null, style = {}, children }: SectionProps): JSX.Element => (
  <SectionStyled style={style} backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
    {children}
  </SectionStyled>
);

export default Section;
