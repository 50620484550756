import styled from 'styled-components';

export const LogoImageStyled = styled.div<{ height: string; width: string }>`
  position: relative;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  line-height: ${(props) => props.height || 'inherit'};
  text-align: center;

  img {
    display: inline-block;
    max-width: ${(props) => props.width || '100%'};
    height: auto;
    vertical-align: middle;
  }
`;
