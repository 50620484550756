import React from 'react';
import Router from './routes';
import Topbar from './components/Topbar/Topbar';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';
import CookieBanner from "./components/CookieBanner/CookieBanner";

function App(): JSX.Element {
  return (
    <>
      {/*<Topbar />*/}
      <Menu />
      <Router />
      <Footer />
      <CookieBanner/>
    </>
  );
}

export default App;

// export default withRouter(_App);
