import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { CloudWiseStyled } from './CloudWise.styled';
import Container from '../../components/Container/Container';
import { CardText } from '../../components/Card/Card.styled';
import Card from '../../components/Card/Card';
import FormRequest from '../../components/Form/FormRequest';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import companyLogo from '../../assets/images/logo/cloud-wise.jpg';
import companyImage from '../../assets/images/companies/cloud-wise.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function CloudWise(): JSX.Element {
  return (
    <CloudWiseStyled>
      <SEO
        title="RGRauto | Cloud-Wise"
      />
      <Container>
        <PageTitle>About Cloud-wise</PageTitle>
        <CompanyCard
          name="Cloud-wise"
          logo={companyLogo}
          image={companyImage}
          buttons={[
            {
              title: 'About I-Sticker',
              url: '/cloud-wise/i-sticker',
            },
          ]}
        >
          <p>
            We believe real-time information about vehicle location and driver behavior has the power to improve road
            safety.
          </p>
          <p>
            Nevertheless, realization of the potential requires a user-friendly, easy to implement, universal data
            platform which overcomes the barriers of current solutions. Current solutions on the market require
            complicated implementation and incurs exhaustive costs incurred by installation, initialization and
            operational fees. In addition, connecting directly to the vehicle results in battery drain, cyber-attacks,
            voiding vehicle warranties and general undue effort and risks.
          </p>
          <p>
            Cloud-Wise was established with the goal of creating a platform that removes these hurdles and enables
            vehicle data collection in a safe, simple, and cost-effective manner.
          </p>
        </CompanyCard>
        <Card>
          <SectionTitle>Request a demo</SectionTitle>
          <CardText>
            To request a demo, please complete the form below <br />
            and we will contact you as soon as possible
          </CardText>
          <FormRequest />
        </Card>
      </Container>
    </CloudWiseStyled>
  );
}

export default CloudWise;
