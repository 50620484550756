import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  palette: {
    textColor: '#001237',
    greenColor: '#00A651',
    blueColor: '#11357E',
    darkBlueColor: '#001C54',
    greyColor: '#F5F5F5',
    errorColor: '#EF5350',
    placeholderColor: '#838383',
    headerBackground: '#000c24',
  },
  media: {
    desktop: '@media (min-width: 1280px)',
    tablet: '@media (min-width: 1024px)',
    phone: '@media (max-width: 1023px)',
  },
};
