import styled, { css } from 'styled-components';

export const AlertsSwitcherWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const Accordion = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  ${(props) => props.theme.media.tablet} {
    flex: 0 0 60%;
    max-width: 60%;
  }

  ${(props) => props.theme.media.desktop} {
    flex: 0 0 70%;
    max-width: 70%;
  }
`;

export const AccordionItemNameWrapper = styled.div``;
export const AccordionItemTitle = styled.div`
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  color: ${(props) => props.theme.palette.blueColor};
`;
export const AccordionItemDescription = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  color: ${(props) => props.theme.palette.blueColor};
  margin-top: 8px;
`;
export const AccordionItemLink = styled.div`
  min-width: 100px;

  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 1px;
  a {
    color: ${(props) => props.theme.palette.greenColor};
    text-decoration: none;
  }
`;
export const AccordionItemIcon = styled.div``;

export const AccordionItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 5px;

  padding: 24px 32px;
  cursor: pointer;

  ${(props) => props.theme.media.tablet} {
    border-right-width: 0;
    border-radius: 5px 0 0 5px;
  }

  & > div {
    margin: 0 8px;
  }

  ${(props) =>
    props.isActive === true &&
    css`
      cursor: auto;
      border-color: ${props.theme.palette.greenColor};

      ${props.theme.media.desktop} {
        margin-left: -32px;
      }
    `};

  // Скрывать
  ${(props) =>
    props.isActive === false &&
    css`
      ${AccordionItemTitle} {
        color: #9e9e9e;
        font-weight: 400;
      }
      ${AccordionItemDescription} {
        display: none;
      }

      ${AccordionItemLink} {
        display: none;
      }

      ${AccordionItemIcon} {
        display: none;
      }
    `};
`;

export const Video = styled.div`
  display: none;
  text-align: center;
  overflow: hidden;

  ${(props) => props.theme.media.tablet} {
    display: block;
    flex: 0 0 40%;
    max-width: 40%;
  }

  ${(props) => props.theme.media.desktop} {
    flex: 0 0 30%;
    max-width: 30%;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  height: 100% !important;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
`;
